"use client";

import React, { useRef, useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";
import ChartDataLabels, { Context } from "chartjs-plugin-datalabels";
import Loader from "../loader/loader";
import "./graphStyles.css";

type AlignType =
  | "start"
  | "center"
  | "end"
  | "left"
  | "right"
  | "top"
  | "bottom";
type AnchorType = "start" | "center" | "end";

Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

interface HorizontalBarChartComponentProps {
  groupData: any;
  selectedGroup: string;
  startDate: Date | null;
  endDate: Date | null;
  loading: boolean;
  shapingCheck: boolean;
  unshapingCheck: boolean;
}

const HorizontalBarChartComponent: React.FC<
  HorizontalBarChartComponentProps
> = ({ groupData, selectedGroup, startDate, endDate, loading, shapingCheck, unshapingCheck }) => {
    const [chartData, setChartData] = useState<any>(null);
    const chartRef = useRef<any>(null);
    
    useEffect(() => {
      if (groupData && selectedGroup && startDate && endDate) {
        const filteredData = groupData.filter((item: any) => {
          const itemDate = new Date(item.startTime);
          const dateInRange = itemDate >= startDate && itemDate <= endDate;
    
          let bitrateCondition = false;
    
          if (shapingCheck && unshapingCheck) {
            // Both checkboxes are checked, include all data
            bitrateCondition = true;
          } else if (shapingCheck) {
            // Only "Shaped Data" is checked, include items with bitrate > 0
            bitrateCondition = item.bitrate > 0;
          } else if (unshapingCheck) {
            // Only "No Shaping Data" is checked, include items with bitrate == 0
            bitrateCondition = item.bitrate === 0;
          } else {
            // Neither checkbox is checked, exclude all data
            bitrateCondition = false;
          }
    
          return dateInRange && bitrateCondition;
        });
        const processedData = processChartData(filteredData);
        setChartData(processedData);
      } else {
        setChartData(null); // Clear the chart data if conditions are not met
      }
    }, [groupData, selectedGroup, startDate, endDate, shapingCheck, unshapingCheck]);
    

    useEffect(() => {
      const resizeChart = () => {
        if (chartRef.current && chartRef.current.chartInstance) {
          chartRef.current.chartInstance.resize();
        }
      };

      window.addEventListener("resize", resizeChart);

      return () => {
        window.removeEventListener("resize", resizeChart);
      };
    }, []);

  const processChartData = (data: any) => {
    const normalizeString = (str: string): string => str.replace(/\s+/g, '').toLowerCase();

    const keywordMapping: { [key: string]: string } = {
      [normalizeString('Prime Video')]: 'Prime Video',
      [normalizeString('Netflix')]: 'Netflix',
      [normalizeString('TikTok')]: 'TikTok',
      [normalizeString('YouTube')]: 'YouTube',
      [normalizeString('Fox Nation')]: 'Fox Nation',
      [normalizeString('Peacock')]: 'Peacock',
      [normalizeString('Hulu')]: 'Hulu',
      [normalizeString('Max')]: 'Max',
      [normalizeString('Rumble')]: 'Rumble',
      [normalizeString('Blaze')]: 'Blaze',
      [normalizeString('ParamountPlus')]: 'Paramount+',
    };

    // Get CSS variables for colors
    const cssVars = getComputedStyle(document.documentElement);
    const colorMapping: {
      [key: string]: { background: string; border: string };
    } = {
      'YouTube': {
        background: cssVars.getPropertyValue("--youtube-red"),
        border: cssVars.getPropertyValue("--youtube-border"),
      },
      'Netflix': {
        background: cssVars.getPropertyValue("--netflix-red"),
        border: cssVars.getPropertyValue("--netflix-border"),
      },
      'Prime Video': {
        background: cssVars.getPropertyValue("--amazon-blue"),
        border: cssVars.getPropertyValue("--amazon-border"),
      },
      'Viki': {
        background: cssVars.getPropertyValue("--viki-blue"),
        border: cssVars.getPropertyValue("--viki-border"),
      },
      'TikTok': {
        background: cssVars.getPropertyValue("--tiktok-blue"),
        border: cssVars.getPropertyValue("--tiktok-border"),
      },
      'Hulu': {
        background: cssVars.getPropertyValue("--hulu-green"),
        border: cssVars.getPropertyValue("--hulu-border"),
      },
      'Peacock': {
        background: cssVars.getPropertyValue("--peacock-yellow"),
        border: cssVars.getPropertyValue("--peacock-border"),
      },
      'Fox Nation': {
        background: cssVars.getPropertyValue("--foxNation"),
        border: cssVars.getPropertyValue("--foxNation-border"),
      },
      'Paramount+': {
        background: cssVars.getPropertyValue("--paramount-blue"),
        border: cssVars.getPropertyValue("--paramount-border"),
      },
      'Max': {
        background: cssVars.getPropertyValue("--max-purple"),
        border: cssVars.getPropertyValue("--max-border"),
      },
      'Rumble': {
        background: cssVars.getPropertyValue("--rumble-orange"),
        border: cssVars.getPropertyValue("--rumble-border"),
      },
      'Blaze': {
        background: cssVars.getPropertyValue("--blaze-pink"),
        border: cssVars.getPropertyValue("--blaze-border"),
      },
      'Other': {
        background: cssVars.getPropertyValue("--other-color"),
        border: cssVars.getPropertyValue("--other-border"),
      },
    };

    // Aggregate data usage per service
    const aggregatedData: {
      [key: string]: { dataUsage: number; duration: number };
    } = {};
    data.forEach((item: any) => {
      const rawNameConf = item.nameConf;
      const nameConf = normalizeString(rawNameConf);
      const dataUsage = item.dataUsage;
      const duration = item.duration;
  
      let domainGroup = "Other";
      for (const keyword in keywordMapping) {
        if (nameConf.includes(keyword)) {
          domainGroup = keywordMapping[keyword];
          break;
        }
      }
      if (aggregatedData[domainGroup]) {
        aggregatedData[domainGroup].dataUsage += dataUsage;
        aggregatedData[domainGroup].duration += duration;
      } else {
        aggregatedData[domainGroup] = { dataUsage, duration };
      }
    });
  
    // **Compute total data usage**
    const totalDataUsage = Object.values(aggregatedData).reduce(
      (sum, data) => sum + data.dataUsage,
      0
    );
  
    // **Filter out services with less than 0.1% of totalDataUsage**
    const minDataUsage = totalDataUsage * 0.01;
    for (const service in aggregatedData) {
      if (aggregatedData[service].dataUsage < minDataUsage) {
        delete aggregatedData[service];
      }
    }
  
    // Sort services by data usage in descending order
    const sortedServices = Object.entries(aggregatedData).sort(
      (a, b) => b[1].dataUsage - a[1].dataUsage
    );
  
    const labels = sortedServices.map(([service]) => service);
    const dataUsage = sortedServices.map(([_, data]) => data.dataUsage);
    const durations = sortedServices.map(([_, data]) => data.duration);
    const backgroundColors = labels.map(
      (label) => colorMapping[label]?.background || "rgba(0, 0, 0, 0.2)"
    );
    const borderColors = labels.map(
      (label) => colorMapping[label]?.border || "rgba(0, 0, 0, 1)"
    );
  
    return {
      labels,
      datasets: [
        {
          label: "Data Usage",
          data: dataUsage,
          backgroundColor: backgroundColors,
          borderColor: borderColors,
          borderWidth: 1,
          borderRadius: 10,
          maxBarThickness: 80,
        },
      ],
      durations,
      totalDataUsage,
    };
  };

  // const formatBytes = (bytes: number, decimals = 2) => {
  //   if (bytes === 0) return "0 Bytes";
  //   const k = 1024;
  //   const dm = decimals < 0 ? 0 : decimals;
  //   const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  //   const i = Math.floor(Math.log(bytes) / Math.log(k));
  //   return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  // };

  const formatBytes = (bytes: number, decimals = 0) => {
    if (bytes === 0) return '0 MB';
    const miB = bytes / (1024 * 1024);
    const dm = decimals < 0 ? 0 : decimals;
    return miB.toFixed(dm) + ' MB';
  };

  const formatDuration = (duration: number) => {
    const days = Math.floor(duration / (60 * 60 * 24));
    const hours = Math.floor(duration / (60 * 60)) % 24;
    const minutes = Math.floor(duration / 60) % 60;
    const seconds = Math.floor(duration) % 60;
    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };

  if (loading) {
    return (
      <div className="centered-container">
        <Loader />
      </div>
    );
  }

  if (!chartData || chartData.labels.length === 0) {
    return (
      <div className="centered-container"></div>
    );
  }

  const totalDataUsage = chartData.totalDataUsage || 0;
  const maxDataValue = Math.max(...chartData.datasets[0].data);

  const options: ChartOptions<"bar"> = {
    indexAxis: "y",
    responsive: true,
    maintainAspectRatio: false,
    layout: {
        padding: {
            right: 40,
        },
    },
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          stepSize: 1 * 1024 * 1024,
          autoSkip: true,
          callback: function (value: any) {
            return formatBytes(value);
          },
          maxTicksLimit: 16,
        },
        suggestedMax: maxDataValue * 1.4
      },
      y: {
        ticks: {
          autoSkip: false,
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context: any) {
            const label = context.dataset.label || "";
            const value = context.raw;
            const duration = chartData.durations[context.dataIndex] || 0;
            return [
              `${label}: ${formatBytes(value, 1)}`,
              `Duration: ${formatDuration(duration)}`,
            ];
          },
        },
      },
      legend: {
        display: false,
      },
      datalabels: {
        align: "end" as AlignType,
        anchor: "end" as AnchorType,
        formatter: function (value: any, context: Context) {
          if (totalDataUsage === 0 || !value) {
            return `${formatBytes(value)}`;
          }
          const percentage = ((value / totalDataUsage) * 100).toFixed(1);
          return `${formatBytes(value)} (${percentage}%)`;
        },
        color: "black",
        font: {
          weight: "bold",
        },
        offset: 8,
        clip: false,
        clamp: true,
      },
    },
  };

  chartData.datasets[0].clip = false;

  return (
    <div
      className="chart-container grid-item"
      // style={{ width: "100%", height: "100%" }}
    >
      {chartData ? (
        <Bar ref={chartRef} data={chartData} options={options} />
      ) : null}
    </div>
  );
};

export default HorizontalBarChartComponent;

"use client";

import React, { useRef, useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart,
    BarElement,
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend,
    ChartOptions,
} from 'chart.js';
import Loader from '../loader/loader';
import { getRemoteConfig } from '../../api/shared';
import { IconButton, InfoSignIcon, Dialog, Paragraph } from 'evergreen-ui';
import './graphStyles.css';

// Register chart components
Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface BarChartComponentProps {
    groupData: any;
    selectedGroup: string;
    startDate: Date | null;
    endDate: Date | null;
    loading: boolean;
    shapingCheck: boolean;
    unshapingCheck: boolean;
}

// Function to fetch remote config data
const getRemoteConfigData = async () => {
    try {
        const response = await getRemoteConfig('baselineMultipliers');

        let data;
        if (typeof response === 'string') {
            data = JSON.parse(response);
        } else {
            data = response;
        }

        const servicesArray = data.services;

        if (!Array.isArray(servicesArray)) {
            console.error('Services array is missing or invalid');
            return null;
        }

        return servicesArray;
    } catch (error) {
        console.error('Error fetching remote config data:', error);
        return null;
    }
};

const BarChartComponent: React.FC<BarChartComponentProps> = ({
    groupData,
    selectedGroup,
    startDate,
    endDate,
    loading,
    shapingCheck,
    unshapingCheck,
}) => {
    const [chartData, setChartData] = useState<any>(null);
    const [baselines, setBaselines] = useState<any>(null);
    const [isDialogShown, setIsDialogShown] = useState(false);
    const chartRef = useRef<any>(null);
    const [baselineUsageData, setBaselineUsageData] = useState<any>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const servicesArray = await getRemoteConfigData();

                // Check if servicesArray is a valid array
                if (!Array.isArray(servicesArray)) {
                    console.error('Services array is missing or invalid');
                    return;
                }

                // Build the baselines object
                const baselinesObj = servicesArray.reduce((acc, service) => {
                    // Access the key dynamically for each service (e.g., youtube, netflix)
                    const key = Object.keys(service)[0];

                    // Access the service details
                    const { name, baselineMultiplier } = service[key];

                    acc[name] = baselineMultiplier; // Map service name to baseline multiplier
                    return acc;
                }, {});
                setBaselines(baselinesObj);
            } catch (error) {
                console.error('Error processing baseline rates:', error);
            }
        };
        fetchData();
    }, []);

    // Fetch baseline usage data
    useEffect(() => {
        if (groupData && selectedGroup && startDate && endDate) {
            console.log(groupData);
            // Determine the baseline date range
            if (startDate > endDate) {
                setErrorMessage('Start date must be before end date');
                return;
            } else {
                setErrorMessage(null);
                const now = new Date();
                let baselineStartDate = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000); // 30 days ago
                let baselineEndDate = now;
                if (endDate.getTime() - startDate.getTime() > 30 * 24 * 60 * 60 * 1000) {
                    // Date range is longer than 30 days
                    baselineStartDate = startDate;
                    baselineEndDate = endDate;
                }

                // Extract noShapingData from groupData over the baseline date range
                const baselineData = groupData.filter((item: any) => {
                    const itemDate = new Date(item.startTime);
                    const dateInBaselineRange = itemDate >= baselineStartDate && itemDate <= baselineEndDate;
                    const bitrateCondition = item.bitrate === 0; // Only bitrate = 0 data

                    return dateInBaselineRange && bitrateCondition;
                });

                setBaselineUsageData(baselineData);
            }
        } else {
            setErrorMessage('Select a group and a date range to view your graphs.');
        }
    }, [groupData, selectedGroup, startDate, endDate]);

    useEffect(() => {
        if (groupData && selectedGroup && startDate && endDate && baselines && baselineUsageData) {
            const filteredData = groupData.filter((item: any) => {
                const itemDate = new Date(item.startTime);
                const dateInRange = itemDate >= startDate && itemDate <= endDate;

                let bitrateCondition = false;

                if (shapingCheck && unshapingCheck) {
                    // Both checkboxes are checked, include all data
                    bitrateCondition = true;
                } else if (shapingCheck) {
                    // Only "Shaped Data" is checked, include items with bitrate > 0
                    bitrateCondition = item.bitrate > 0;
                } else if (unshapingCheck) {
                    // Only "No Shaping Data" is checked, include items with bitrate === 0
                    bitrateCondition = item.bitrate === 0;
                } else {
                    // Neither checkbox is checked, exclude all data
                    bitrateCondition = false;
                }

                return dateInRange && bitrateCondition;
            });
            const processedData = processChartData(
                filteredData,
                baselines,
                unshapingCheck,
                shapingCheck,
                baselineUsageData
            );
            setChartData(processedData);
        } else {
            setChartData(null);
        }
    }, [
        groupData,
        selectedGroup,
        startDate,
        endDate,
        baselines,
        shapingCheck,
        unshapingCheck,
        baselineUsageData,
    ]);

    useEffect(() => {
        const resizeChart = () => {
            if (chartRef.current && chartRef.current.chartInstance) {
                chartRef.current.chartInstance.resize();
            }
        };

        window.addEventListener('resize', resizeChart);

        return () => {
            window.removeEventListener('resize', resizeChart);
        };
    }, []);

    const processChartData = (
        data: any,
        baselines: any,
        shapingCheck: boolean,
        unshapingCheck: boolean,
        baselineUsageData: any
    ) => {
        // Use YouTube baseline multiplier
        const youtubeBaselineMultiplier = baselines['YouTube'] || baselines['Default'];
        const baselineRateMiBPerSec = youtubeBaselineMultiplier / 3600; // Convert from MiB/hr to MiB/s
    
        // Separate data based on bitrate
        const dataWithBitrateZero = data.filter((item: any) => item.bitrate === 0);
        const dataWithBitrateGreaterThanZero = data.filter((item: any) => item.bitrate > 0);
    
        // Calculate actual usage
        const totalDataUsage = data.reduce((acc: number, item: any) => acc + item.dataUsage, 0); // in bytes
        const totalDuration = data.reduce((acc: number, item: any) => acc + item.duration, 0); // in seconds
    
        // Calculate projected usage for bitrate == 0 (use dataUsage as-is)
        const dataUsageBitrateZero = dataWithBitrateZero.reduce(
            (acc: number, item: any) => acc + item.dataUsage,
            0
        ); // in bytes
    
        // Calculate projected usage for bitrate > 0 (duration * baselineRate)
        const totalDurationBitrateGreaterThanZero = dataWithBitrateGreaterThanZero.reduce(
            (acc: number, item: any) => acc + item.duration,
            0
        ); // in seconds
    
        const dataUsageBitrateGreaterThanZero =
            totalDurationBitrateGreaterThanZero * baselineRateMiBPerSec * 1024 * 1024; // Convert MiB to bytes
    
        // Total projected usage
        const projectedUsageBytes = dataUsageBitrateZero + dataUsageBitrateGreaterThanZero;
    
        const labels = ['All Services'];
        const dataUsage = [totalDataUsage];
        const baselineDataUsage = [projectedUsageBytes];
        const totalDurations = [totalDuration];
    
        // Define colors
        const cssVars = getComputedStyle(document.documentElement);
        const actualUsageColor = {
            background: cssVars.getPropertyValue('--other-color') || 'rgba(0, 0, 255, 0.5)',
            border: cssVars.getPropertyValue('--other-border') || 'rgba(0, 0, 255, 1)',
        };
        const baselineColor = {
            background: cssVars.getPropertyValue('--baseline-black') || 'rgba(255, 0, 0, 0.5)',
            border: cssVars.getPropertyValue('--baseline-border') || 'rgba(255, 0, 0, 1)',
        };
    
        const datasets = [
            {
                label: 'Actual Usage',
                data: dataUsage,
                backgroundColor: [actualUsageColor.background],
                borderColor: [actualUsageColor.border],
                borderWidth: 1,
                borderRadius: 10,
            },
            {
                label: 'Projected Usage',
                data: baselineDataUsage,
                backgroundColor: [baselineColor.background],
                borderColor: [baselineColor.border],
                borderWidth: 1,
                borderRadius: 10,
            },
        ];
    
        return {
            labels,
            datasets,
            durations: totalDurations,
        };
    };

    const formatBytes = (bytes: number, decimals = 1) => {
        if (bytes === 0) return '0 MB';
        const miB = bytes / (1024 * 1024);
        const dm = decimals < 0 ? 0 : decimals;
        return miB.toFixed(dm) + ' MB';
    };

    const formatDuration = (duration: number) => {
        const days = Math.floor(duration / (60 * 60 * 24));
        const hours = Math.floor(duration / (60 * 60)) % 24;
        const minutes = Math.floor(duration / 60) % 60;
        const seconds = Math.floor(duration) % 60;
        return `${days}d ${hours}h ${minutes}m ${seconds}s`;
    };

    if (loading) {
        return (
            <div className="centered-container">
                <Loader />
            </div>
        );
    }

    if (!chartData || chartData.labels.length === 0) {
        if (errorMessage) {
            return (
                <div className="centered-container">
                    <div className="how-to">{errorMessage}</div>
                </div>
            );
        }
    }

    if (!chartData || chartData.labels.length === 0) {
        return (
            <div className="centered-container">
                <div className="how-to">
                    Select a group and a date range to view your graphs.
                </div>
            </div>
        );
    }

    const options: ChartOptions<'bar'> = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    stepSize: 1 * 1024 * 1024, // Step size in bytes (1 MiB)
                    autoSkip: true,
                    callback: function (value: any) {
                        // Display no decimal points on Y-axis
                        return formatBytes(value, 0);
                    },
                    maxTicksLimit: 17,
                },
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context: any) {
                        const label = context.dataset.label || '';
                        const value = context.raw;
                        const duration = chartData.durations[context.dataIndex];
                        // Display one decimal point in tooltip
                        return [
                            `${label}: ${formatBytes(value, 1)}`,
                            `Duration: ${formatDuration(duration)}`,
                        ];
                    },
                },
            },
            datalabels: {
                display: false,
            },
        },
    };

    return (
        <div 
            className="chart-container grid-item"
            // style={{ width: '100%', height: '100%', position: 'relative' }}
        >
            {chartData ? (
                <>
                    <IconButton
                        icon={InfoSignIcon}
                        onClick={() => setIsDialogShown(true)}
                        style={{ position: 'absolute', top: 10, right: 10 }}
                    />
                    <Bar ref={chartRef} data={chartData} options={options} />
                    <Dialog
                        isShown={isDialogShown}
                        title="Bar Chart Information"
                        onCloseComplete={() => setIsDialogShown(false)}
                        hasFooter={false}
                    >
                        <Paragraph style={{ marginBottom: 3 }}>
                            The <strong>"Actual Usage"</strong> bar represents the total data usage across all services in the selected date range.
                        </Paragraph>
                        <Paragraph style={{ marginBottom: 3 }}>
                            The <strong>"Projected Usage"</strong> is calculated using all of SkyPeak Technologies information from various clients and tests. We use unshaped video streams to calculate the average datarate then we multiply that by the total duration of the data in the selected date range.
                        </Paragraph>
                    </Dialog>
                </>
            ) : (
                <div className="how-to">
                    Select a group and a date range to view your graphs.
                </div>
            )}
        </div>
    );
};

export default BarChartComponent;

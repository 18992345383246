import React from 'react';
import { apiEnv } from '../../constants/api';
import {
  TextInput,
  Paragraph,
  Pane,
  Text,
  Dialog,
  Button,
  toaster,
} from 'evergreen-ui';
import { RootGroup, shapingValue } from '../../models/models';

type ServiceShapingValues = {
  low: shapingValue;
  medium: shapingValue;
  high: shapingValue;
  none: shapingValue;
};

interface AdvSettingsProps {
  currentService: string;
  advSettingsState: {
    isShown: boolean;
    lowBitrate?: number;
    lowDataRate?: number;
    mediumBitrate?: number;
    mediumDataRate?: number;
    highBitrate?: number;
    highDataRate?: number;
    noneBitrate?: number;
    noneDataRate?: number;
  };
  setAdvSettingsState: React.Dispatch<
    React.SetStateAction<{
      isShown: boolean;
      lowBitrate?: number;
      lowDataRate?: number;
      mediumBitrate?: number;
      mediumDataRate?: number;
      highBitrate?: number;
      highDataRate?: number;
      noneBitrate?: number;
      noneDataRate?: number;
    }>
  >;
  setShapingValues: (newValues: ServiceShapingValues) => void;
}

const AdvSettings: React.FC<AdvSettingsProps> = ({
  currentService,
  advSettingsState,
  setAdvSettingsState,
  setShapingValues,
}) => {
  const handleInputChange = (field: string, value: number) => {
    setAdvSettingsState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleSave = () => {
    const {
      lowBitrate,
      lowDataRate,
      mediumBitrate,
      mediumDataRate,
      highBitrate,
      highDataRate,
      noneBitrate,
      noneDataRate,
    } = advSettingsState;

    if (
      [lowBitrate, lowDataRate, mediumBitrate, mediumDataRate,
       highBitrate, highDataRate, noneBitrate, noneDataRate].some(
        (value) => value === undefined || Number.isNaN(value)
      )
    ) {
      toaster.danger('Please fill out all fields', { duration: 8 });
      return;
    }

    const newValues: ServiceShapingValues = {
      low: { target: lowBitrate!, targetDataRate: lowDataRate! },
      medium: { target: mediumBitrate!, targetDataRate: mediumDataRate! },
      high: { target: highBitrate!, targetDataRate: highDataRate! },
      none: { target: noneBitrate!, targetDataRate: noneDataRate! },
    };

    setShapingValues(newValues);
    toaster.success('Values saved successfully', { duration: 8 });

    // Close the dialog
    setAdvSettingsState((prevState) => ({
      ...prevState,
      isShown: false,
    }));
  };

  if (apiEnv !== 'internal') {
    return null;
  }

  return (
    <div>
      {/* <span
        className="advancedText"
        onClick={() =>
          setAdvSettingsState((prevState) => ({ ...prevState, isShown: true }))
        }
      >
      </span> */}
      <Dialog
        isShown={advSettingsState.isShown}
        title={`${currentService} Shaping Values`}
        onCloseComplete={() =>
          setAdvSettingsState((prevState) => ({ ...prevState, isShown: false }))
        }
        hasFooter={true}
        footer={<Button onClick={handleSave}>Save</Button>}
      >
        <Pane>
          {/* Low */}
          <Paragraph>
            <strong className="large_text">Low:</strong>
          </Paragraph>
          <Pane display="flex" alignItems="center" paddingLeft={16}>
            <Text style={{ fontWeight: '600', marginRight: 16 }}>
              Target Bitrate
            </Text>
            <TextInput
              type="number"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange('lowBitrate', parseInt(e.target.value))
              }
              placeholder="Enter Bitrate"
              name="lowBitrate"
              value={advSettingsState.lowBitrate ?? ''}
            />
          </Pane>
          <Pane display="flex" alignItems="center" paddingLeft={16}>
            <Text style={{ fontWeight: '600', marginRight: 16 }}>
              Target Data Rate
            </Text>
            <TextInput
              type="number"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange('lowDataRate', parseInt(e.target.value))
              }
              placeholder="Enter Data Rate"
              name="lowDataRate"
              value={advSettingsState.lowDataRate ?? ''}
            />
          </Pane>
          {/* Repeat for Medium, High, and None */}
          {/* Medium */}
          <Paragraph>
            <strong className="large_text">Medium:</strong>
          </Paragraph>
          <Pane display="flex" alignItems="center" paddingLeft={16}>
            <Text style={{ fontWeight: '600', marginRight: 16 }}>
              Target Bitrate
            </Text>
            <TextInput
              type="number"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange('mediumBitrate', parseInt(e.target.value))
              }
              placeholder="Enter Bitrate"
              name="mediumBitrate"
              value={advSettingsState.mediumBitrate ?? ''}
            />
          </Pane>
          <Pane display="flex" alignItems="center" paddingLeft={16}>
            <Text style={{ fontWeight: '600', marginRight: 16 }}>
              Target Data Rate
            </Text>
            <TextInput
              type="number"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange('mediumDataRate', parseInt(e.target.value))
              }
              placeholder="Enter Data Rate"
              name="mediumDataRate"
              value={advSettingsState.mediumDataRate ?? ''}
            />
          </Pane>
          {/* High */}
          <Paragraph>
            <strong className="large_text">High:</strong>
          </Paragraph>
          <Pane display="flex" alignItems="center" paddingLeft={16}>
            <Text style={{ fontWeight: '600', marginRight: 16 }}>
              Target Bitrate
            </Text>
            <TextInput
              type="number"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange('highBitrate', parseInt(e.target.value))
              }
              placeholder="Enter Bitrate"
              name="highBitrate"
              value={advSettingsState.highBitrate ?? ''}
            />
          </Pane>
          <Pane display="flex" alignItems="center" paddingLeft={16}>
            <Text style={{ fontWeight: '600', marginRight: 16 }}>
              Target Data Rate
            </Text>
            <TextInput
              type="number"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange('highDataRate', parseInt(e.target.value))
              }
              placeholder="Enter Data Rate"
              name="highDataRate"
              value={advSettingsState.highDataRate ?? ''}
            />
          </Pane>
          {/* None */}
          <Paragraph>
            <strong className="large_text">None:</strong>
          </Paragraph>
          <Pane display="flex" alignItems="center" paddingLeft={16}>
            <Text style={{ fontWeight: '600', marginRight: 16 }}>
              Target Bitrate
            </Text>
            <TextInput
              type="number"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange('noneBitrate', parseInt(e.target.value))
              }
              placeholder="Enter Bitrate"
              name="noneBitrate"
              value={advSettingsState.noneBitrate ?? ''}
            />
          </Pane>
          <Pane display="flex" alignItems="center" paddingLeft={16}>
            <Text style={{ fontWeight: '600', marginRight: 16 }}>
              Target Data Rate
            </Text>
            <TextInput
              type="number"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange('noneDataRate', parseInt(e.target.value))
              }
              placeholder="Enter Data Rate"
              name="noneDataRate"
              value={advSettingsState.noneDataRate ?? ''}
            />
          </Pane>
        </Pane>
      </Dialog>
    </div>
  );
};

export default AdvSettings;

import React, { Suspense, useEffect, useState } from 'react';
import { Heading, Pane, Paragraph, SideSheet, toaster, TextInput, majorScale, Combobox } from 'evergreen-ui';
import { createNewAccount, getGroups } from '../../api/shared';
import errorHandler from "../errorMessages/errorHandler";
import CountryPicker from "./CountryPicker";

type Country = {
    countryCode: string;
    countryName: string;
    dialCode: string;
    flag: string;
};

interface GroupOption {
    label: string;
    value: string;
};

const CreateNewAccount:React.FC <{
    isOpen: boolean;
    onClose: () => void;
  }> = ({isOpen, onClose}) => {
    const [groups, setGroups] = useState<GroupOption[]>([]);
    const [selectedGroup, setSelectedGroup] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [country, setCountry] = useState<Country>({ countryCode: 'US', countryName: 'United States', dialCode: '+1', flag: '🇺🇸' });
  
    useEffect(() => {
      fetchGroups();
    }, [isOpen]);
  
    const handleCreateSave = async (
        name: string,
        selectedGroup: string,
        country: Country
      ) => {
        // Remove any non-numeric characters from 'name'
        const numericName = name.replace(/\D/g, '');
      
        if (numericName.length < 10 || numericName.length > 12) {
          toaster.danger('Account name must contain 10 - 12 digits', { duration: 10 });
          return;
        }

        if (selectedGroup === "") {
          toaster.danger(
            'Please select a group',
            { duration: 10 }
          );
          return;
        }
        
        const fullAccountID = `${country.dialCode}${numericName}`;
      
        try {
          await createNewAccount({
            accountId: fullAccountID,
            group: selectedGroup,
          });
          toaster.success('Account created successfully', { duration: 8 });
          // Reset the form
          setName("");
          setSelectedGroup("");
          setCountry({ countryCode: 'US', countryName: 'United States', dialCode: '+1', flag: '🇺🇸' });
          // Close the side sheet
          onClose();
        } catch (error: any) {
          errorHandler(error);
          console.error('Error creating account', error);
      
          let errorMessage = 'An unexpected error occurred';
          
          try {
              const errorResponse = JSON.parse(error.response.text);
              errorMessage = errorResponse.detail || errorMessage;
          } catch (parseError) {
              console.error('Failed to parse error response:', parseError);
          }
      
          toaster.danger(errorMessage, { duration: 10 });
      }
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const input = event.target.value;
      // Remove any non-digit characters
      const numericInput = input.replace(/\D/g, '');
      // Update the state with numeric input
      const limitedInput = numericInput.slice(0, 12);
      setName(limitedInput);
    };
  
    const fetchGroups = async () => {
      try {
        const groupNames = await getGroups();
        const formattedGroups = groupNames.map(group => ({ label: group, value: group }));
        setGroups(formattedGroups);
      } catch (error) {
        console.error("Error fetching groups:", error);
      }
    };
  
    return(
      <SideSheet
        isShown={isOpen}
        onCloseComplete={onClose}
        preventBodyScrolling
      >
        <Pane padding={16} borderBottom="muted">
          <Heading size={600} marginBottom={majorScale(2)}>Create New Account</Heading>
        </Pane>
        {
          <React.Fragment>
            <Pane padding={16}>
              <Paragraph><strong>Account ID:</strong></Paragraph>
              <Pane style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', marginBottom: '16px'}}>
                <CountryPicker
                  selectedCountry={country}
                  onSelect={(selectedCountry) => {
                    setCountry(selectedCountry);
                  }}
                />
                <TextInput
                  name="account-id"
                  placeholder="Enter Account ID"
                  value={name}
                  onChange={handleNameChange}
                  inputMode='numeric'
                  maxLength={12}
                  pattern='\d*'
                />
              </Pane>
            </Pane>
            <Pane padding={16}>
              <Paragraph><strong>Group:</strong></Paragraph>
              <Suspense fallback={<div>Loading...</div>}>
                <Combobox
                  items={groups}
                  itemToString={(item) => item ? item.label : ""}
                  onChange={(selected) => setSelectedGroup(selected ? selected.value : "")}
                  placeholder="Select group..."
                />
              </Suspense>
            </Pane>
            <Pane padding={16}>
              <button
                className="button-green button-padding"
                onClick={() => {
                  handleCreateSave(name, selectedGroup, country)
                }}
              >
                Save
              </button>
              <button
                className="button-red button-padding"
                onClick={onClose}
              >
                Cancel
              </button>
            </Pane>
          </React.Fragment>
        }
      </SideSheet>
    )
  };

export default CreateNewAccount;
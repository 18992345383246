import React, { useState } from 'react';
import {
  Dialog,
  Pane,
  Heading,
  Table,
  Paragraph,
  SelectMenu,
  Button,
  Position,
  SavedIcon,
  toaster,
} from 'evergreen-ui';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ChartData, ChartOptions } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart } from 'react-chartjs-2';
import { format } from 'date-fns';


// Import and register the necessary chart types
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

interface RootAccount {
  id: string;
  accountId: string;
  configTag: string;
  enabled: boolean;
  deviceOs: string;
  coraV: string;
  installed: string | null;
  status: string;
}

interface StatsDialogProps {
  isOpen: boolean;
  onClose: () => void;
  data?: RootAccount[];
}

function convertToISOString(dateString: string): string {
  // Step 1: Remove ' UTC' entirely
  let cleanedDateString = dateString.replace(' UTC', '');

  // Step 2: Replace the space between date and time with 'T'
  cleanedDateString = cleanedDateString.replace(' ', 'T');

  // **Add this step to remove any remaining spaces**
  cleanedDateString = cleanedDateString.replace(/\s+/g, '');

  // Step 3: Handle timezones
  // Convert '+0000' to 'Z' (UTC)
  if (cleanedDateString.includes('+0000')) {
    cleanedDateString = cleanedDateString.replace('+0000', 'Z');
  } else {
    // For non-UTC timezones, convert '+HHmm' to '+HH:mm'
    cleanedDateString = cleanedDateString.replace(
      /([+-]\d{2})(\d{2})$/,
      '$1:$2'
    );
  }

  // Step 4: Ensure milliseconds are present
  const timePart = cleanedDateString.split('T')[1]; // Grab the time part
  if (timePart && timePart.length === 8) {
    cleanedDateString = cleanedDateString.replace('Z', '.000Z'); // Add milliseconds if missing
  }

  return cleanedDateString;
}

const StatsDialog: React.FC<StatsDialogProps> = ({
  isOpen,
  onClose,
  data = [],
}) => {
  const [selectedGroup, setSelectedGroup] = useState<string>('All Groups');

  // Initialize startDate and endDate to the last 30 days
  const today = new Date();
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(today.getDate() - 30);

  const [startDate, setStartDate] = useState<Date>(new Date(new Date().setMonth(new Date().getMonth() - 1)));
  const [endDate, setEndDate] = useState<Date>(new Date());

  // Quick Date Range Functions
  const setLast30Days = () => {
    const today = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - 30);
    setStartDate(thirtyDaysAgo);
    setEndDate(today);
  };

  const setLast3Months = () => {
    const today = new Date();
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(today.getMonth() - 3);
    setStartDate(threeMonthsAgo);
    setEndDate(today);
  };

  const setLastYear = () => {
    const today = new Date();
    const lastYear = new Date();
    lastYear.setFullYear(today.getFullYear() - 1);
    setStartDate(lastYear);
    setEndDate(today);
  };

  const handleStartDateChange = (date: Date | null) => {
    if (date) {
      const startOfDay = new Date(date.setHours(0, 0, 0, 0));
      setStartDate(startOfDay);
    } else {
      setStartDate(new Date());
    }
  }

  const handleEndDateChange = (date: Date | null) => {
    if (date) {
      const endOfDay = new Date(date.setHours(23, 59, 59, 999));
      setEndDate(endOfDay);
    } else {
      setEndDate(new Date());
    }
  }

  // Derive available groups from the data
  const availableGroups = React.useMemo(() => {
    if (!data) return [];
    const groupSet = new Set<string>();
    data.forEach((account) => {
      if (account.configTag) {
        groupSet.add(account.configTag);
      }
    });
    return Array.from(groupSet);
  }, [data]);

  // Filter and sort activated accounts
  const activatedAccounts = React.useMemo(() => {
    if (!data || data.length === 0) return [];

    return data
      .filter((account) => {
        const hasInstalled = account.installed && account.installed !== 'N/A';
        const inGroup =
          selectedGroup === 'All Groups' || account.configTag === selectedGroup;

        if (!hasInstalled) return false;
        const installedDateString = convertToISOString(account.installed!);
        const installedDate = new Date(installedDateString);

        if (isNaN(installedDate.getTime())){
          console.error('Invalid date:', installedDateString);
          return false;
        }

        const inDateRange = 
          installedDate >= startDate && installedDate <= endDate;
        
        return inGroup && inDateRange;
      })
      .sort((a, b) => {
        const dateA = new Date(convertToISOString(a.installed!)).getTime();
        const dateB = new Date(convertToISOString(b.installed!)).getTime();
        return dateA - dateB;
      });
  }, [data, selectedGroup, startDate, endDate]);

  // Prepare chart data
  const chartData = React.useMemo(() => {
    if (activatedAccounts.length === 0) return null;

    // Calculate daily activation counts
    const dailyCountsMap: { [date: string]: number } = {};

    activatedAccounts.forEach((account) => {
      const installedDate = new Date(convertToISOString(account.installed!));
      if (!isNaN(installedDate.getTime())) {
        const dateLable = installedDate.toISOString().split('T')[0];
        dailyCountsMap[dateLable] = (dailyCountsMap[dateLable] || 0) + 1;
      }
    });

    // Get all unique dates and sort them
    const allDates = Object.keys(dailyCountsMap).sort((a, b) => {
      return new Date(a).getTime() - new Date(b).getTime();
    });

    // Prepare cumulative counts
    const cumulativeDataPoints: number[] = [];
    let cumulativeCount = 0;

    // Prepare daily counts
    const dailyDataPoints: number[] = [];

    allDates.forEach((date) => {
      const dailyCount = dailyCountsMap[date] || 0;
      cumulativeCount += dailyCount;
      dailyDataPoints.push(dailyCount);
      cumulativeDataPoints.push(cumulativeCount);
    });
    return {
      labels: allDates,
      datasets: [
        {
          type: 'line',
          label: 'Cumulative Activations',
          data: cumulativeDataPoints,
          fill: false,
          borderColor: 'rgb(0, 0, 0)',
          tension: 0.1,
          yAxisID: 'y1',
          datalabels: {
            align: 'top',
            anchor: 'end',
            offset: 5,
            overlap: false,
            clip: true,
          },
        },
        {
          type: 'bar',
          label: 'Daily Activations',
          data: dailyDataPoints,
          borderColor: 'rgba(76, 184, 72, 1)',
          backgroundColor: 'rgba(76, 184, 72, 0.2)',
          yAxisID: 'y2',
          datalabels: {
            align: 'center',
            anchor: 'center',
            offset: 5,
            overlap: false,
            clip: true,
          },
        },
      ],
    } as unknown as ChartData<'line' | 'bar'>;
  }, [activatedAccounts]);

  const maxDataValue = chartData?.datasets && chartData.datasets[0]?.data
  ? Math.max(
      ...(chartData.datasets[0].data.filter((value) => typeof value === 'number') as number[]),
      0
    )
  : 0; // Fallback to 0 if chartData or datasets[0] is undefined


  const chartOptions: ChartOptions<'bar' | 'line'> = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
    },
    scales: {
      x: {
        type: 'category',
        title: {
          display: true,
          text: 'Date',
        },
        grid: {
            display: true,
        },
        ticks: {
            autoSkip: true,
            maxRotation: 45,
            minRotation: 45,
            maxTicksLimit: 30,
        },
      },
      y1: {
        type: 'linear',
        position: 'left',
        title: {
          display: true,
          text: 'Cumulative Activations',
        },
        grid: {
          display: true,
        },
        ticks: {
          stepSize: 10,
          maxTicksLimit: 10,
          precision: 0,
        },
        suggestedMax: maxDataValue + 10,
      },
      y2: {
        type: 'linear',
        position: 'right',
        title: {
          display: true,
          text: 'Daily Activations',
        },
        grid: {
          drawOnChartArea: false, // Prevent grid lines from y2 overlapping with y1
        },
        ticks: {
          stepSize: 10,
          maxTicksLimit: 10,
          precision: 0,
        },
      },
    },
  };

  const handleDownloadCSV = () => {
    if (activatedAccounts.length === 0) {
      toaster.warning('No data to download', { duration: 8 });
      return;
    }

    // Prepare CSV content
    const headers = ['Account ID', 'Group', 'Activation Date'];
    const rows = activatedAccounts.map((account) => [
      account.accountId,
      account.configTag,
      account.installed
        ? new Date(account.installed).toLocaleString()
        : 'N/A',
    ]);

    const csvContent = [headers, ...rows].map((e) => e.join(',')).join('\n');

    // Create a blob and trigger download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);

    link.setAttribute('href', url);
    link.setAttribute('download', 'activated_users.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Dialog
      isShown={isOpen}
      title="Activation Statistics"
      onCloseComplete={onClose}
      confirmLabel="Close"
      width={1200}
      hasFooter={false}
    >
      <Pane>
        {/* Filters and Actions */}
        <Pane display="flex" alignItems="center" marginBottom={16} gap={16}>
          {/* Group Filter */}
          <SelectMenu
            title="Select Group"
            options={['All Groups', ...availableGroups].map((group) => ({
              label: group,
              value: group,
            }))}
            selected={selectedGroup}
            onSelect={(item) => setSelectedGroup(item.value.toString())}
            closeOnSelect={true}
            position={Position.BOTTOM_LEFT}
          >
            <Button>{selectedGroup} ▼</Button>
          </SelectMenu>

          {/* Date Range Picker */}
          <Pane display="flex" alignItems="center" gap={8}>
            <DatePicker
              selected={startDate}
              onChange={handleStartDateChange}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              placeholderText="Start Date"
            />
            <DatePicker
              selected={endDate}
              onChange={handleEndDateChange}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              placeholderText="End Date"
            />
          </Pane>

          {/* Quick Date Range Buttons */}
          <Pane display="flex" alignItems="center" gap={8}>
            <Button onClick={setLast30Days}>Last 30 Days</Button>
            <Button onClick={setLast3Months}>Last 3 Months</Button>
            <Button onClick={setLastYear}>Last Year</Button>
          </Pane>

          {/* Spacer */}
          <Pane flex={1} />

          {/* Download CSV Button */}
          <Button
            appearance="primary"
            iconBefore={SavedIcon}
            onClick={handleDownloadCSV}
          >
            Download CSV
          </Button>
        </Pane>

        {chartData ? (
          <>
            <Heading size={600} marginBottom={16}>
              Activation Over Time
            </Heading>
            <Chart data={chartData} options={chartOptions} type={'line'} />
          </>
        ) : (
          <Paragraph>No activation data available.</Paragraph>
        )}

        {activatedAccounts.length > 0 && (
          <>
            <Heading size={600} marginTop={32} marginBottom={16}>
              Activated Users
            </Heading>
            <Table>
              <Table.Head>
                <Table.TextHeaderCell>Account ID</Table.TextHeaderCell>
                <Table.TextHeaderCell>Group</Table.TextHeaderCell>
                <Table.TextHeaderCell>Activation Date</Table.TextHeaderCell>
              </Table.Head>
              <Table.Body>
                {activatedAccounts.map((account) => (
                  <Table.Row key={account.accountId}>
                    <Table.TextCell>{account.accountId}</Table.TextCell>
                    <Table.TextCell>{account.configTag}</Table.TextCell>
                    <Table.TextCell>
                      {account.installed
                        ? format(
                            new Date(convertToISOString(account.installed!)),
                            'yyyy-MM-dd HH:mm:ss'
                          )
                        : 'N/A'}
                    </Table.TextCell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </>
        )}
      </Pane>
    </Dialog>
  );
};

export default StatsDialog;

'use client';

import { Link, useLocation } from 'react-router-dom';
import { 
    IconUsersGroup, //Use stroke 2px
    IconUser, //Use stroke 2px
    IconChartHistogram, //Use stroke 2px
 } from '@tabler/icons-react';
import clsx from 'clsx';

const links = [
    {name: 'Dashboard', href: '/dashboard', icon: IconChartHistogram},
    {name: 'Groups', href: '/groups', icon: IconUsersGroup},
    {name: 'Accounts', href: '/accounts', icon: IconUser},
    // {name: 'Change Password', href: '/change-password', icon: IconUser},
];

export default function NavLinks() {
    const location = useLocation();
    const pathname = location.pathname;
    return (
        <>
            {links.map((link) => {
                const LinkIcon = link.icon;
                return (
                    <Link
                        key={link.name}
                        to={link.href}
                        className={clsx("theme flex h-[48px] grow items-center justify-center gap-2 rounded-md bg-gray-50 p-3 text-sm font-medium hover:bg-sky-100 hover:text-blue-600 md:flex-none md:justify-start md:p-2 md:px-3",
                        {
                            'bg-sky-100 text-blue-600': pathname === link.href,
                        },
                        )}
                        >
                        <LinkIcon className="w-6" />
                        <p className="hidden md:block" style={{ fontFamily: 'Roboto, sans-serif' }}>{link.name}</p>
                    </Link>
                );
            })}
        </>
    );
}
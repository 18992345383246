import React, { useEffect, useState } from "react";
import {
  getRootGroups,
  getGroupInfo,
  updateGroup,
  createGroup,
  getRemoteConfig,
} from "../../api/shared";
import { apiEnv } from "../../constants/api";
import { RootGroup, shapingData, shapingValue } from "../../models/models";
import { errorHandler } from "../errorMessages/errorHandler";
import { toaster } from "evergreen-ui";
import {
  Pane,
  Table,
  majorScale,
  SideSheet,
  Heading,
  Paragraph,
  TextInput,
  Switch,
  Text,
  Combobox,
  Pagination,
} from "evergreen-ui";
import "./styles.css";
import {
  defaultShapingLevel,
  ShapingLevel,
  shapingLevels as allShapingLevels,
  toShapingLevel,
} from "../../models/ShapingLevel";
import AdvSettings from "./advanced";
import LoadingWord from "../loader/loadingWord";

// Defining a type for the AdvSettings so we can manage them from this level
type AdvSettingsState = {
  isShown: boolean;
  lowBitrate?: number;
  lowDataRate?: number;
  mediumBitrate?: number;
  mediumDataRate?: number;
  highBitrate?: number;
  highDataRate?: number;
  noneBitrate?: number;
  noneDataRate?: number;
};

// This is the default group that will be created when the create button is clicked
const newGroup: RootGroup = {
  name: "",
  bypassWiFiTraffic: true,
  levelOfSavings: defaultShapingLevel.name,
  shapingData: {}, // Ensure shapingData is initialized
};

const getRemoteConfigData = async () => {
  try {
    const response = await getRemoteConfig("domainsConfigurationV3");
    let data;
    if (typeof response === "string") {
      data = JSON.parse(response);
    } else {
      data = response;
    }

    // Extract servicesList from data.domains
    const servicesList = data.domains || [];

    // Build shapingValuesConfig from data.domains
    const shapingValuesConfig: { [key: string]: ServiceShapingValues } = {};

    servicesList.forEach((service: any) => {
      const serviceKey = service.name; // service tag
      if (service.rateLimit) {
        shapingValuesConfig[serviceKey] = {
          low: {
            target: service.rateLimit.low || 0,
            targetDataRate: service.rateLimit.lowDataRate || 0,
          },
          medium: {
            target: service.rateLimit.medium || 0,
            targetDataRate: service.rateLimit.mediumDataRate || 0,
          },
          high: {
            target: service.rateLimit.high || 0,
            targetDataRate: service.rateLimit.highDataRate || 0,
          },
          none: {
            target: service.rateLimit.none || 0,
            targetDataRate: service.rateLimit.noneDataRate || 0,
          },
        };
      }
    });

    return { servicesList, shapingValuesConfig };
  } catch (error) {
    console.error("Error fetching remote config data:", error);
    return null;
  }
};

const itemsPerPage = 10;

type ServiceShapingValues = {
  low: shapingValue;
  medium: shapingValue;
  high: shapingValue;
  none: shapingValue;
};

const GroupRootList: React.FC = () => {
  // State variables
  const [loading, setLoading] = useState<boolean>(true);
  const [rootData, setRootData] = useState<RootGroup[]>([]);
  const [isSideSheetOpen, setIsSideSheetOpen] = useState<boolean>(false);
  const [selectedGroup, setSelectedGroup] = useState<RootGroup | null>(null);
  const [newGroupName, setNewGroupName] = useState<string>("");
  const [bypassWiFiTraffic, setBypassWiFiTraffic] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [servicesList, setServicesList] = useState<any[]>([]);
  const [shapingValuesConfig, setShapingValuesConfig] = useState<any>({});
  const [serviceNameMap, setServiceNameMap] = useState<{ [key: string]: string }>({});
  const [serviceShapingLevels, setServiceShapingLevels] = useState<{
    [serviceName: string]: ShapingLevel;
  }>({});
  const [serviceShapingValues, setServiceShapingValues] = useState<{
    [serviceName: string]: ServiceShapingValues;
  }>({});
  const [advSettingsState, setAdvSettingsState] = useState<AdvSettingsState>({
    isShown: false,
  });
  const [selectedService, setSelectedService] = useState<string | null>(null);

  // This function is used to reset the shaping data when the user saves all changes to a group
  const resetShapingData = () => {
    setServiceShapingValues({});
  };

  useEffect(() => {
    if (selectedService) {
      const shapingValues = serviceShapingValues[selectedService] || {
        low: { target: 0, targetDataRate: 0 },
        medium: { target: 0, targetDataRate: 0 },
        high: { target: 0, targetDataRate: 0 },
        none: { target: 0, targetDataRate: 0 },
      };
      setAdvSettingsState((prevState) => ({
        ...prevState,
        lowBitrate: shapingValues.low.target,
        lowDataRate: shapingValues.low.targetDataRate,
        mediumBitrate: shapingValues.medium.target,
        mediumDataRate: shapingValues.medium.targetDataRate,
        highBitrate: shapingValues.high.target,
        highDataRate: shapingValues.high.targetDataRate,
        noneBitrate: shapingValues.none.target,
        noneDataRate: shapingValues.none.targetDataRate,
      }));
    }
  }, [selectedService]);

  // This function is called when the page is loaded and it grabs the group data.
  useEffect(() => {
    fetchData();
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        event.preventDefault();
        setIsSideSheetOpen(false);
      }
    };

    window.addEventListener("keydown", handleEsc);
    return () => window.removeEventListener("keydown", handleEsc);
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await getRootGroups();
      setRootData(data);
  
      // Fetch both remote configs in parallel
      const [domainsConfig, baselineConfigData] = await Promise.all([
        getRemoteConfigData(),
        getRemoteConfig('baselineMultipliers'),
      ]);
  
      if (domainsConfig && baselineConfigData) {
        const domainsServices = domainsConfig.servicesList || [];
        const baselineServicesArray = baselineConfigData.services || [];
        const nameMap: { [key: string]: string } = {};
  
        // Build nameMap from baselineMultipliers
        baselineServicesArray.forEach((serviceObj: { [key: string]: { name: string } }) => {
          const serviceKey: string = Object.keys(serviceObj)[0];
          const serviceDetails: { name: string } = serviceObj[serviceKey];
          nameMap[serviceKey] = serviceDetails.name;
        });
  
        // Build servicesList with both serviceKey and displayName
        const combinedServicesList: Array<{ serviceKey: string; displayName: string; [key: string]: any }> = domainsServices.map((service: any) => {
          const serviceKey: string = service.name; // service tag from domainsConfig
          const displayName: string = nameMap[serviceKey] || serviceKey;
          return { ...service, serviceKey, displayName };
        });
  
        setServicesList(combinedServicesList);
        setShapingValuesConfig(domainsConfig.shapingValuesConfig || {});
        setServiceNameMap(nameMap);
      }
    } catch (error) {
      errorHandler(error);
      console.error("Error getting groups", error);
    } finally {
      setLoading(false);
    }
  };
  
  // Define available shaping levels
  const availableShapingLevelNames = ["none", "low"];

  const shapingLevels = allShapingLevels.filter((level) =>
    availableShapingLevelNames.includes(level.name)
  );

  // This function updates shaping data when a group is selected
  const updateShapingData = (group: RootGroup | null) => {
    let newServiceShapingLevels: { [serviceName: string]: ShapingLevel } = {};
    let newServiceShapingValues: { [serviceName: string]: ServiceShapingValues } = {};

    servicesList.forEach((service) => {
      const serviceKey = service.serviceKey;
      const shapingLevel =
        group?.shapingData?.[serviceKey]?.target
          ? toShapingLevel(group.shapingData[serviceKey].target as string) ||
            defaultShapingLevel
          : defaultShapingLevel;

      newServiceShapingLevels[serviceKey] = shapingLevel;

      newServiceShapingValues[serviceKey] = {
        low:
          group?.shapingData?.[serviceKey]?.low ||
          shapingValuesConfig[serviceKey]?.low || { target: 0, targetDataRate: 0 },
        medium:
          group?.shapingData?.[serviceKey]?.medium ||
          shapingValuesConfig[serviceKey]?.medium || {
            target: 0,
            targetDataRate: 0,
          },
        high:
          group?.shapingData?.[serviceKey]?.high ||
          shapingValuesConfig[serviceKey]?.high || {
            target: 0,
            targetDataRate: 0,
          },
        none:
          group?.shapingData?.[serviceKey]?.none ||
          shapingValuesConfig[serviceKey]?.none || {
            target: 0,
            targetDataRate: 0,
          },
      };
    });

    setServiceShapingLevels(newServiceShapingLevels);
    setServiceShapingValues(newServiceShapingValues);
  };

  const handleRowClick = async (rowData: RootGroup) => {
    try {
      const data = await getGroupInfo(rowData.name);
      if (!data) {
        throw new Error("No group data found");
      }
      setSelectedGroup(data);
      setNewGroupName(data.name || "");
      setBypassWiFiTraffic(data.bypassWiFiTraffic || false);
      setShapingLevels(data);
      resetShapingData();
      updateShapingData(data);
    } catch (error) {
      toaster.warning("Error", {
        description: "Error getting group data",
        duration: 8,
      });
      console.error("Error getting group data", error);
    } finally {
      setIsSideSheetOpen(true);
    }
  };
  

  const handleCreateClick = async (newGroup: RootGroup) => {
    resetState();
    setNewGroupName(newGroup.name);
    setBypassWiFiTraffic(newGroup.bypassWiFiTraffic);
    setSelectedGroup(newGroup);
    setShapingLevels(newGroup);
    updateShapingData(null); // Initialize shaping data for a new group
    setIsSideSheetOpen(true);
  };

  const setShapingLevels = (group: RootGroup) => {
    let newServiceShapingLevels: { [serviceName: string]: ShapingLevel } = {};
    if (group.shapingData) {
      for (const serviceKey in group.shapingData) {
        const shapingLevel =
          toShapingLevel(group.shapingData[serviceKey]?.target as string) ||
          defaultShapingLevel;
        newServiceShapingLevels[serviceKey] = shapingLevel;
      }
    } else {
      // Initialize shaping levels for a new group
      servicesList.forEach((service) => {
        const serviceKey = service.serviceKey;
        newServiceShapingLevels[serviceKey] = defaultShapingLevel;
      });
    }
    setServiceShapingLevels(newServiceShapingLevels);
  };

  // This save function will save the values of the group settings including shaping values
  const handleSave = async () => {
    if (selectedGroup) {
      const formData: RootGroup = {
        name: newGroupName || "",
        bypassWiFiTraffic: bypassWiFiTraffic,
        levelOfSavings: selectedGroup.levelOfSavings,
        shapingData: {}, // Initialize shapingData here
      };

      servicesList.forEach((service) => {
        const serviceKey = service.serviceKey;
        formData.shapingData[serviceKey] = {
          target: serviceShapingLevels[serviceKey]?.name || defaultShapingLevel.name,
          ...(serviceShapingValues[serviceKey] || {}), // This will make sure that it is always initialized
        };
      });

      // This is the try catch block that will try and send the group data to the server
      try {
        if (selectedGroup.name) {
          await updateGroup({
            oldName: selectedGroup.name,
            groupData: formData,
          });
        } else {
          await createGroup(formData);
        }
        toaster.success("Group updated successfully", { duration: 8 });
        await fetchData();
        setIsSideSheetOpen(false);
        resetShapingData();
      } catch (error: any) {
        if (error.response) {
          switch (error.response.status) {
            case 422:
              toaster.warning("Invalid entry", {
                description:
                  "One or more fields are invalid. Please check and try again.",
                duration: 8,
              });
              break;
            case 409:
              toaster.warning("Invalid entry", {
                description: "Group name already exists",
                duration: 8,
              });
              break;
            default:
              toaster.danger("Error updating group", {
                description: error.response.data.message,
                duration: 8,
              });
          }
        } else {
          errorHandler(error);
        }
      }
    }
  };

  const resetState = () => {
    setSelectedGroup(null);
    setNewGroupName("");
    setBypassWiFiTraffic(false);
    setSelectedService(null);
    setServiceShapingLevels({});
    setServiceShapingValues({});
    setAdvSettingsState({
      isShown: false,
      lowBitrate: undefined,
      lowDataRate: undefined,
      mediumBitrate: undefined,
      mediumDataRate: undefined,
      highBitrate: undefined,
      highDataRate: undefined,
      noneBitrate: undefined,
      noneDataRate: undefined,
    });
  };

  const handleSearch = (value: string) => {
    setSearchQuery(value);
    setCurrentPage(1);
  };

  const filteredData = rootData.filter((group) =>
    group.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = filteredData.slice(startIndex, endIndex);

  const handleServiceNameClick = (serviceKey: string) => {
    if (apiEnv === "internal") {
      setSelectedService(serviceKey);
      setAdvSettingsState({
        ...advSettingsState,
        isShown: true,
      });
    }
  };

  const handleShapingLevelChange = (serviceKey: string, selected: ShapingLevel) => {
    if (selected.name === "medium" || selected.name === "high") {
      toaster.warning("Shaping level not available", {
        description: "This shaping level is not available for this group",
        duration: 8,
      });
      return;
    }
    setServiceShapingLevels((prevLevels) => ({
      ...prevLevels,
      [serviceKey]: selected,
    }));
  };

  if (loading) {
    return <LoadingWord />;
  }

  return (
    <Pane>
      <Pane
        display="flex"
        justifyContent="flex-end"
        marginBottom={majorScale(2)}
      >
        <button className="Btn" onClick={() => handleCreateClick(newGroup)}>
          <div className="sign">+</div>
          <div className="text">Create</div>
        </button>
      </Pane>
      {/*---------------------------------------------
        This is the table that displays the group data. It is a table that displays the group name, connection policy, and default shaping level.
        The table is also searchable and paginated.
      ---------------------------------------------*/}
      <Table className="table">
        <Table.Head className="table-header">
          <Table.SearchHeaderCell
            onChange={handleSearch}
            placeholder="Search groups..."
          />
          {/* <Table.TextHeaderCell>Example of a new header</Table.TextHeaderCell> */}
        </Table.Head>
        <Table.Body>
          {currentItems.map((group, index) => (
            <Table.Row
              className="table-row"
              key={index}
              isSelectable
              onSelect={() => handleRowClick(group)}
            >
              <Table.TextCell className="table-cell">{group.name}</Table.TextCell>
              {/* <Table.TextCell className="table-cell">
                example of what a new cell would look like
              </Table.TextCell> */}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <Pagination
        page={currentPage}
        totalPages={Math.ceil(filteredData.length / itemsPerPage)}
        onPageChange={(page: number) => setCurrentPage(page)}
      />
      {/*---------------------------------------------
        This is the side sheet that pops up when a group is clicked. It displays the group details and allows the user to edit the group settings.
        The side sheet also has a save and cancel button.
      ---------------------------------------------*/}
      <SideSheet
        isShown={isSideSheetOpen}
        onCloseComplete={() => {
          setIsSideSheetOpen(false);
          resetState();
        }}
      >
        <Pane padding={16} borderBottom="muted">
          <Heading size={600}>Group Details</Heading>
        </Pane>
        <Pane padding={16}>
          {selectedGroup && (
            <>
              <Paragraph>
                <strong className="large_text">Name:</strong>
              </Paragraph>
              <TextInput
                disabled={true}
                value={newGroupName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setNewGroupName(e.target.value)
                }
                marginBottom={16}
              />
              {/* Keeping this in a comment because we are going to use it later */}
              {/* <Paragraph>
                <strong className="large_text">Connection Policy:</strong>
              </Paragraph>
              <Pane display="flex" alignItems="center" marginBottom={16}>
                <Text color="muted">Cellular and WiFi</Text>
                <Switch
                  checked={true}
                  onChange={() => {}}
                  height={24}
                  marginLeft={8}
                  marginRight={8}
                  disabled={true} // Disable the switch
                />
                <Text>Cellular only</Text>
              </Pane> */}
              <Paragraph>
                <strong className="large_text">Shaping Levels:</strong>{" "}
              </Paragraph>
            </>
          )}

          {/* Services Table */}
          {servicesList.map((service) => {
            const serviceKey = service.serviceKey; // service tag
            const displayName = service.displayName; // user-friendly name
            return (
              <Table.Row key={serviceKey}>
                <Table.TextCell>
                  <Text onClick={() => handleServiceNameClick(serviceKey)}>
                    {displayName}
                  </Text>
                </Table.TextCell>
                <Table.Cell>
                  <Combobox
                    width="100%"
                    items={shapingLevels}
                    selectedItem={
                      serviceShapingLevels[serviceKey] || defaultShapingLevel
                    }
                    onChange={(selected) =>
                      handleShapingLevelChange(serviceKey, selected)
                    }
                    itemToString={(item) => (item ? item.label : "")}
                    placeholder="Select shaping level"
                  />
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Pane>

        <Pane padding={16}>
          <button className="button-green button-padding" onClick={handleSave}>
            Save
          </button>
          <button
            className="button-red button-padding"
            onClick={() => setIsSideSheetOpen(false)}
          >
            Cancel
          </button>
        </Pane>
      </SideSheet>

      {/* Advanced Settings Modal */}
      {selectedService && (
        <AdvSettings
          currentService={selectedService}
          advSettingsState={advSettingsState}
          setAdvSettingsState={setAdvSettingsState}
          setShapingValues={(newValues: ServiceShapingValues) => {
            setServiceShapingValues((prevValues) => ({
              ...prevValues,
              [selectedService]: newValues,
            }));
          }}
        />
      )}
    </Pane>
  );
};

export default GroupRootList;

"use client";

import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
// import { useRouter } from 'next/navigation';
import styles from './login.module.css';
import { login } from '../api/shared';
import { EyeOpenIcon, EyeOffIcon } from 'evergreen-ui';
import LogoSvgFunc from '../components/logoSvg';
import { Suspense } from 'react';

const LoadingDots = () => {
  return (
    <div className={styles.loader}>
      <div className={styles.ball}></div>
      <div className={styles.ball}></div>
      <div className={styles.ball}></div>
    </div>
  );
};

const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const router =  useNavigate();

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setIsLoading(true);
        const response = await login(username, password);
        if (response && response.access_token && response.changePasswordIsRequired) {
            router('/change-password');
        } else if (response && response.access_token) {
            router('/dashboard');
        } else {
            alert('Login failed');
            setIsLoading(false);
        }
    };

    const imageTag = process.env.REACT_APP_IMAGE_TAG || 'unknown';

  return (
    <div className={styles.container}>
      <div className={styles.loginCard}>
        <LogoSvgFunc />
        <h2 className={styles.heading}>Enterprise Console Portal</h2>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.inputGroup}>
            <label className={styles.label} htmlFor="username"></label>
            <input
              className={styles.input}
              type="text"
              id="username"
              name="username"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className={styles.inputGroup}>
            <label className={styles.label} htmlFor="password"></label>
            <div className={styles.passwordWrapper}>
              <input
                className={styles.input}
                type={showPassword ? 'text' : 'password'}
                id="password"
                name="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <button
                type="button"
                className={styles.showPasswordButton}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <EyeOffIcon /> : <EyeOpenIcon />}
              </button>
            </div>
          </div>
          <div className={styles.buttonWrapper}>
            <button className={styles.customButton} type="submit" disabled={isLoading}>
              {isLoading ? <LoadingDots /> : 'Log In'}
            </button>
          </div>
        </form>
        <div className={styles.footer}>
        </div>
      </div>
      <div className={styles.imageTag}>v{imageTag}</div>
    </div>
  );
};

export default LoginPage;
import React from 'react';
import { Dialog, Pane, FileUploader, FileCard, Portal, Spinner, Heading } from 'evergreen-ui';

//This is for the file uploader
interface FileObject {
    name: string;
    size: number;
    type: string;
}

//This is for the file rejection
interface FileRejection {
    file: FileObject;
    message: string;
}

const FileUploaderSingleUpload: React.FC<{
    isOpen: boolean;
    onClose: () => void;
    onImport: (file: File) => Promise<void>;
  }> = ({ isOpen, onClose, onImport }) => {
    const [files, setFiles] = React.useState<File[]>([]);
    const [fileRejections, setFileRejections] = React.useState<FileRejection[]>([]);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
  
    const handleChange = React.useCallback((acceptedFiles: File[]) => {
      const csvFiles = acceptedFiles.filter(
        (file) => file.type === 'text/csv' || file.name.endsWith('.csv')
      );
      if (csvFiles.length > 0) {
        setFiles([csvFiles[0]]);
      } else {
        alert('Please upload a valid CSV file.');
      }
    }, []);
  
    const handleRejected = React.useCallback((rejections: FileRejection[]) => {
      setFileRejections(rejections);
    }, []);
  
    const handleRemove = React.useCallback(() => {
      setFiles([]);
      setFileRejections([]);
    }, []);
  
    const handleImport = async () => {
      if (files.length > 0) {
        setIsSubmitting(true);
        await onImport(files[0]);
        setIsSubmitting(false);
        onClose(); // Close the dialog after submission
      }
    };
  
    const handleConfirm = async () => {
      await handleImport();
      handleRemove();
    };
  
    return (
      <>
        <Dialog
          isShown={isOpen}
          onCloseComplete={onClose}
          onConfirm={handleConfirm}
          confirmLabel="Save"
          isConfirmDisabled={files.length === 0 || isSubmitting}
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEscapePress={false}
          hasClose={!isSubmitting} // Hide the Close button during submission
        >
          {/* Disable content interaction when submitting */}
          <Pane pointerEvents={isSubmitting ? 'none' : 'auto'}>
            <FileUploader
              label="Upload File"
              description="You can upload 1 file. File can be up to 50 MB."
              maxSizeInBytes={50 * 1024 ** 2}
              maxFiles={1}
              onChange={handleChange}
              onRejected={handleRejected}
              renderFile={(file: File) => {
                const fileRejection = fileRejections.find(
                  (fr) => fr.file.name === file.name
                );
                return (
                  <FileCard
                    key={file.name}
                    isInvalid={fileRejection != null}
                    name={file.name}
                    onRemove={handleRemove}
                    sizeInBytes={file.size}
                    type={file.type}
                    validationMessage={
                      fileRejection ? fileRejection.message : undefined
                    }
                  />
                );
              }}
              values={files}
            />
          </Pane>
        </Dialog>
        {/* Overlay with Loading spinner */}
        {isSubmitting && (
          <Portal>
            <Pane
              position="fixed"
              top={0}
              left={0}
              right={0}
              bottom={0}
              display="flex"
              alignItems="center"
              justifyContent="center"
              background="rgba(0, 0, 0, 0.6)"
              zIndex={9999}
              pointerEvents="all" // Ensure overlay captures all pointer events
              onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()} // Prevent click events from propagating
            >
              <Pane
                background="white"
                padding={16}
                borderRadius={4}
                textAlign="center"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <Spinner size={40} />
                <Heading size={600} marginTop={16}>
                  Importing Accounts...
                </Heading>
              </Pane>
            </Pane>
          </Portal>
        )}
    </>
  );
};

export default FileUploaderSingleUpload;
"use client";
import React, { Suspense, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
// import { useRouter } from 'next/navigation';
import GroupRootList from "../ui/groups/rootGroups";
import Layout from '../components/layout';

export default function Page() {
    const router = useNavigate();
  
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const token = sessionStorage.getItem('authData');

      if (!token) {
        router('/login');
      }
    }
  }, []);
    return (
      <Layout>
        <div>
            <Suspense fallback={<div>Loading...</div>}>
                <GroupRootList />
            </Suspense>
        </div>
      </Layout>
    );
}
import React, { useState } from 'react';
import { Pane, Button, Dialog, SearchInput, ListItem } from 'evergreen-ui';

// Import your existing countryList
import countryList from '../../models/countryCode';

// Define the Country type if not already defined
type Country = {
  countryCode: string;
  countryName: string;
  dialCode: string;
  flag: string;
};

type CountryPickerProps = {
  onSelect: (country: Country) => void;
  selectedCountry?: Country | null;
  defaultCountryCode?: string;
};

const CountryPicker: React.FC<CountryPickerProps> = ({ 
    onSelect,
    selectedCountry,
    defaultCountryCode = 'US',
}) => {
  const [isShown, setIsShown] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredCountries, setFilteredCountries] = useState<Country[]>(countryList);

  const defaultCountry = countryList.find(
    country => country.countryCode === defaultCountryCode
  );

  const displayCountry = selectedCountry || defaultCountry;

  const handleSearchChange = (value: string) => {
    setSearchQuery(value);
    const filtered = countryList.filter(country =>
      country.countryName.toLowerCase().includes(value.toLowerCase()) ||
      country.dialCode.includes(value)
    );
    setFilteredCountries(filtered);
  };

  const handleCloseComplete = () => {
    setIsShown(false);
    setSearchQuery('');
    setFilteredCountries(countryList);
  };

  return (
    <Pane>
      <Button onClick={() => setIsShown(true)} appearance="minimal" padding={8}>
        {displayCountry ? (
          <Pane display="flex" alignItems="center">
            <span style={{ fontSize: 20 }}>{displayCountry.flag}</span>
            <span style={{ marginLeft: 8 }}>{displayCountry.dialCode}</span>
          </Pane>
        ) : (
          'Select a country'
        )}
      </Button>
      <Dialog
        isShown={isShown}
        title="Select a Country"
        onCloseComplete={handleCloseComplete}
        hasFooter={false}
      >
        <SearchInput
          placeholder="Search..."
          value={searchQuery}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSearchChange(e.target.value)}
          marginBottom={16}
        />
        <Pane maxHeight={300} overflowY="auto">
          {filteredCountries.map(country => (
            <ListItem
              key={country.countryCode}
              onClick={() => {
                onSelect(country);
                setIsShown(false);
              }}
              cursor="pointer"
              padding={8}
              display="flex"
              alignItems="center"
            >
              <span style={{ fontSize: 20, marginRight: 8 }}>{country.flag}</span>
              <span style={{ flexGrow: 1 }}>{country.countryName}</span>
              <span>{country.dialCode}</span>
            </ListItem>
          ))}
        </Pane>
      </Dialog>
    </Pane>
  );
};

export default CountryPicker;

import React from "react";
import { toaster } from "evergreen-ui";

export const errorHandler = (error: any) => {
    if (error.response) {
        if (error.response.status === 401) {
            toaster.warning('Unauthorized', {description: 'Please login again', duration: 8});
            return 'Unauthorized';
        }
        if (error.response.status === 403) {
            toaster.warning('Forbidden', {duration: 8});
            return 'Forbidden';
        }
        if (error.response.status === 404) {
            toaster.warning('Not Found', {duration: 8});
            return 'Not Found';
        }
        if (error.response.status === 500) {
            toaster.warning('Internal Server Error', {duration: 8});
            return 'Internal Server Error';
        }
        if (error.response.status === 502) {
            toaster.warning('Bad Gateway', {duration: 8});
            return 'Bad Gateway';
        }
        if (error.response.status === 503) {
            toaster.warning('Service Unavailable', {duration: 8});
            return 'Service Unavailable';
        }
        if (error.response.status === 504) {
            toaster.warning('Gateway Timeout', {duration: 8});
            return 'Gateway Timeout';
        }
    }
    toaster.danger('Unknown Error', {duration: 10});
    return 'Unknown Error';
};

export default errorHandler;
import React from 'react';
import {useState, useEffect} from 'react';

import env from '../config';
import LogoSvg from '../Logo.svg';

const LogoSvgFunc: React.FC = () => {
    const [logoImg, setLogoImg] = useState(LogoSvg);

    useEffect(
        () => {
            const cmsBase = env.REACT_APP_CDN_BASE;
            const cmsDir = env.REACT_APP_CDN_ENV;
            const url = cmsBase + "/" + cmsDir + "/Logo.svg";
            setLogoImg(url);
        }, []
    )

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '20px',
          }}>
            <img src={logoImg} alt="Logo" width={100} height={100}/>
        </div>
    );
};

export default LogoSvgFunc;
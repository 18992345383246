import React, {useEffect, useState} from "react";
import {getDeviceCount} from "../../api/shared";
import { Pane } from "evergreen-ui";
import "./other.css"

interface GroupSelection {
  groupName: string;
};

const DeviceNum: React.FC<GroupSelection> = ({groupName}) => {
  const [deviceCount, setDeviceCount] = useState<number>(0);

  useEffect(() => {
    if (groupName !== "") {
      getDeviceCount(groupName).then((count) => {
        setDeviceCount(count);
      });
    }
  }, [groupName]);

  return (
    <div>
      <p className="device-count">{deviceCount}</p>
    </div>
  );
}

export default DeviceNum;

"use client";

import { createContext, useContext, useState, useEffect, ReactNode } from "react";
import { Dialog } from "evergreen-ui";
import { useNavigate } from "react-router-dom";

// Create a context to provide auth functions and state
const AuthContext = createContext({});

export const useAuth = () => useContext(AuthContext);

export function GlobalAuthProvider({ children }: { children: ReactNode }) {
  const [isDialogShown, setIsDialogShown] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthToken = () => {
      const refreshData = localStorage.getItem("refreshData");

      if (!refreshData) {
        navigate("/login");
        return;
      }

      const parsedData = JSON.parse(refreshData);
      if (!parsedData.isLoggedIn || !parsedData.expired) {
        navigate("/login");
        return;
      }

      const expirationTime = parsedData.expired;
      const currentTime = Date.now();
      const timeRemaining = expirationTime - currentTime;

      if (timeRemaining <= 300000 && timeRemaining > 0) {
        setIsDialogShown(true);
      }

      if (timeRemaining <= 0) {
        setIsDialogShown(false);
        navigate("/login");
      }
    };

    const intervalId = setInterval(checkAuthToken, 60000); // 1 minute

    checkAuthToken(); // Run the check on mount

    return () => clearInterval(intervalId);
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem("refreshData");
    sessionStorage.removeItem("authData");
    setIsDialogShown(false);
    navigate("/login");
  };

  const handleDialogCancel = () => {
    setIsDialogShown(false);
  }

  return (
    <div style={{ zIndex: 9999}}>
    <AuthContext.Provider value={{}}>
      {children}
      <Dialog
        isShown={isDialogShown}
        title="Session Expiring"
        intent="warning"
        onConfirm={handleLogout}
        confirmLabel="Log out now"
        onCancel={handleDialogCancel}
        preventBodyScrolling={true}
        shouldAutoFocus={true}
        >
        Your session is about to expire. Please save your work. You will be logged out soon.
      </Dialog>
    </AuthContext.Provider>
    </div>
  );
}
"use client";

import { useNavigate } from 'react-router-dom';
import React, { Suspense, useState, useEffect } from "react";
import AccountRootList from '../ui/accounts/rootAccounts';
import Layout from '../components/layout';
import "./accountsstyles.css";

export default function Page() {
  const router = useNavigate();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const token = sessionStorage.getItem('authData');
      if (!token) {
        router('/login');
      } else {
      }
    }
  }, []);

  return (
    <Layout>
      <div>
          <Suspense fallback={
            <div className="spinner">
              <div></div>   
              <div></div>    
              <div></div>    
              <div></div>    
              <div></div>    
              <div></div>    
              <div></div>    
              <div></div>    
              <div></div>    
              <div></div>    
            </div>
          }>
              <AccountRootList />
          </Suspense>
      </div>
    </Layout>
);
}
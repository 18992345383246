import React, { useState, useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, LineElement, CategoryScale, LinearScale, Title, Tooltip, Legend, PointElement } from 'chart.js';
import Loader from '../loader/loader';
import './graphStyles.css';

Chart.register(LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

interface LineChartComponentProps {
    groupData: any;
    selectedGroup: string;
    startDate: Date | null;
    endDate: Date | null;
    loading: boolean;
    shapingCheck: boolean;
    unshapingCheck: boolean;
}

const LineChartComponent: React.FC<LineChartComponentProps> = ({ groupData, selectedGroup, startDate, endDate, loading, shapingCheck, unshapingCheck }) => {
    const [chartData, setChartData] = useState<any>(null);
    const chartRef = useRef<any>(null);

    useEffect(() => {
        if (groupData && selectedGroup && startDate && endDate) {
          const filteredData = groupData.filter((item: any) => {
            const itemDate = new Date(item.startTime);
            const dateInRange = itemDate >= startDate && itemDate <= endDate;
      
            let bitrateCondition = false;
      
            if (shapingCheck && unshapingCheck) {
              // Both checkboxes are checked, include all data
              bitrateCondition = true;
            } else if (shapingCheck) {
              // Only "Shaped Data" is checked, include items with bitrate > 0
              bitrateCondition = item.bitrate > 0;
            } else if (unshapingCheck) {
              // Only "No Shaping Data" is checked, include items with bitrate == 0
              bitrateCondition = item.bitrate === 0;
            } else {
              // Neither checkbox is checked, exclude all data
              bitrateCondition = false;
            }
      
            return dateInRange && bitrateCondition;
          });
          const processedData = processChartData(filteredData, startDate, endDate);
          setChartData(processedData);
        } else {
          setChartData(null); // Clear the chart data if conditions are not met
        }
      }, [groupData, selectedGroup, startDate, endDate, shapingCheck, unshapingCheck]);

    useEffect(() => {
        const resizeChart = () => {
            if (chartRef.current && chartRef.current.chartInstance) {
                chartRef.current.chartInstance.resize();
            }
        };

        window.addEventListener('resize', resizeChart);

        return () => {
            window.removeEventListener('resize', resizeChart);
        };
    }, []);

    const processChartData = (data: any, startDate: Date, endDate: Date) => {
        // Determine the aggregation granularity
        const dateDiff = endDate.getTime() - startDate.getTime();
        const oneDay = 1000 * 60 * 60 * 24;
        const oneMonth = oneDay * 30;
        let aggregationType = '';

        if (dateDiff <= oneDay * 31) {
            aggregationType = 'daily';
        } else if (dateDiff <= oneMonth * 4) {
            aggregationType = 'weekly';
        } else {
            aggregationType = 'monthly';
        }

        // Generate labels for the date range based on aggregationType
        const labels = generateDateLabels(startDate, endDate, aggregationType);

        // Initialize aggregatedData with zeros for all labels and services
        const aggregatedData: { [label: string]: { [service: string]: number } } = {};
        labels.forEach(label => {
            aggregatedData[label] = {};
        });

        // Keyword and color mappings remain the same
        const normalizeString = (str: string): string => str.replace(/\s+/g, '').toLowerCase();

        const keywordMapping: { [key: string]: string } = {
            [normalizeString('Prime Video')]: 'Prime Video',
            [normalizeString('Netflix')]: 'Netflix',
            [normalizeString('TikTok')]: 'TikTok',
            [normalizeString('YouTube')]: 'YouTube',
            [normalizeString('Fox Nation')]: 'Fox Nation',
            [normalizeString('Peacock')]: 'Peacock',
            [normalizeString('Hulu')]: 'Hulu',
            [normalizeString('Max')]: 'Max',
            [normalizeString('Rumble')]: 'Rumble',
            [normalizeString('Blaze')]: 'Blaze',
            [normalizeString('ParamountPlus')]: 'Paramount+',
        };

        const cssVars = getComputedStyle(document.documentElement);
        const colorMapping: { [key: string]: { background: string, border: string } } = {
            'YouTube': {
                background: cssVars.getPropertyValue('--youtube-red'),
                border: cssVars.getPropertyValue('--youtube-border'),
            },
            'Netflix': {
                background: cssVars.getPropertyValue('--netflix-red'),
                border: cssVars.getPropertyValue('--netflix-border'),
            },
            'Prime Video': {
                background: cssVars.getPropertyValue('--amazon-blue'),
                border: cssVars.getPropertyValue('--amazon-border'),
            },
            'Viki': {
                background: cssVars.getPropertyValue('--viki-blue'),
                border: cssVars.getPropertyValue('--viki-border'),
            },
            'TikTok': {
                background: cssVars.getPropertyValue('--tiktok-blue'),
                border: cssVars.getPropertyValue('--tiktok-border'),
            },
            'Hulu': {
                background: cssVars.getPropertyValue('--hulu-green'),
                border: cssVars.getPropertyValue('--hulu-border'),
            },
            'Peacock': {
                background: cssVars.getPropertyValue('--peacock-yellow'),
                border: cssVars.getPropertyValue('--peacock-border'),
            },
            'Fox Nation': {
                background: cssVars.getPropertyValue('--foxNation'),
                border: cssVars.getPropertyValue('--foxNation-border'),
            },
            'Paramount+': {
                background: cssVars.getPropertyValue('--paramount-blue'),
                border: cssVars.getPropertyValue('--paramount-border'),
            },
            'Max': {
                background: cssVars.getPropertyValue('--max-purple'),
                border: cssVars.getPropertyValue('--max-border'),
            },
            'Rumble': {
                background: cssVars.getPropertyValue('--rumble-orange'),
                border: cssVars.getPropertyValue('--rumble-border'),
            },
            'Blaze': {
                background: cssVars.getPropertyValue('--blaze-pink'),
                border: cssVars.getPropertyValue('--blaze-border'),
            },
            'Other': {
                background: cssVars.getPropertyValue('--other-color'),
                border: cssVars.getPropertyValue('--other-border'),
            },
        };

        // Collect services and aggregate data
        const servicesSet = new Set<string>();

        data.forEach((item: any) => {
            const itemDate = new Date(item.startTime);
            const dataUsage = item.dataUsage;

            const normalizedNameConf = normalizeString(item.nameConf);

            let domainGroup = 'Other';
            for (const keyword in keywordMapping) {
                if (normalizedNameConf.includes(keyword)) {
                    domainGroup = keywordMapping[keyword];
                    break;
                }
            }

            servicesSet.add(domainGroup);

            const label = getLabelForDate(itemDate, aggregationType);

            if (!aggregatedData[label]) {
                aggregatedData[label] = {};
            }

            if (!aggregatedData[label][domainGroup]) {
                aggregatedData[label][domainGroup] = 0;
            }

            aggregatedData[label][domainGroup] += dataUsage;
        });

        // Ensure zeros for services with no data on certain labels
        const services = Array.from(servicesSet);
        labels.forEach(label => {
            services.forEach(service => {
                if (!aggregatedData[label][service]) {
                    aggregatedData[label][service] = 0;
                }
            });
        });

        // Prepare datasets
        const datasets = services.map(service => {
            const serviceData = labels.map(label => aggregatedData[label][service] || 0);
            return {
                label: service,
                data: serviceData,
                backgroundColor: colorMapping[service]?.background || 'rgba(0, 0, 0, 0.2)',
                borderColor: colorMapping[service]?.border || 'rgba(0, 0, 0, 1)',
                borderWidth: 1,
                fill: false,
                tension: 0.1,
            };
        });

        return {
            labels,
            datasets,
        };
    };

    const generateDateLabels = (startDate: Date, endDate: Date, aggregationType: string): string[] => {
        const labels = [];
        const currentDate = new Date(startDate);

        if (aggregationType === 'daily') {
            while (currentDate <= endDate) {
                labels.push(currentDate.toISOString().split('T')[0]);
                currentDate.setDate(currentDate.getDate() + 1);
            }
        } else if (aggregationType === 'weekly') {
            // Start from the Sunday of the first week
            currentDate.setDate(currentDate.getDate() - currentDate.getDay());
            while (currentDate <= endDate) {
                labels.push(currentDate.toISOString().split('T')[0]);
                currentDate.setDate(currentDate.getDate() + 7);
            }
        } else if (aggregationType === 'monthly') {
            currentDate.setDate(1); // Start from the first day of the month
            while (currentDate <= endDate) {
                const monthLabel = `${currentDate.getFullYear()}-${('0' + (currentDate.getMonth() + 1)).slice(-2)}`;
                labels.push(monthLabel);
                currentDate.setMonth(currentDate.getMonth() + 1);
            }
        }

        return labels;
    };

    const getLabelForDate = (date: Date, aggregationType: string): string => {
        if (aggregationType === 'daily') {
            return date.toISOString().split('T')[0];
        } else if (aggregationType === 'weekly') {
            const weekStart = new Date(date);
            weekStart.setDate(weekStart.getDate() - weekStart.getDay());
            return weekStart.toISOString().split('T')[0];
        } else if (aggregationType === 'monthly') {
            return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}`;
        }
        return '';
    };

    const formatBytes = (bytes: number, decimals = 0) => {
        if (bytes === 0) return '0 MB';
        const miB = bytes / (1024 * 1024);
        const dm = decimals < 0 ? 0 : decimals;
        return miB.toFixed(dm) + ' MB';
    };

    if (loading) {
        return <div className="centered-container"><Loader /></div>;
    }

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    stepSize: 1 * 1024 * 1024,
                    maxTicksLimit: 15,
                    callback: function(value: any) {
                        return formatBytes(value);
                    }
                }
            }
        },
        plugins: {
            legend: {
                position: 'top' as const,
            },
            tooltip: {
                callbacks: {
                    label: function (context: any) {
                        const label = context.dataset.label || '';
                        const value = context.raw;
                        return `${label}: ${formatBytes(value,1)}`;
                    },
                },
            },
            datalabels: {
                display: false,
            }
        },
    };

    if (
        !chartData ||
        chartData.datasets.length === 0 ||
        chartData.datasets.every((dataset:any) => dataset.data.length === 0)
      ) {
        return null;
      }
      
      return (
        <div
          className="chart-container grid-item"
        //   style={{ width: '100%', height: '80vh' }}
        >
          <Line ref={chartRef} data={chartData} options={options} />
        </div>
      );
};

export default LineChartComponent;

export type ShapingLevel = {
    name: string;
    label: string;
}

export const NoneShapingLevel: ShapingLevel = {name: "none", label: "None"}
export const LowShapingLevel: ShapingLevel = {name: "low", label: "Low"}
export const MediumShapingLevel: ShapingLevel = {name: "medium", label: "Medium"}
export const HighShapingLevel: ShapingLevel = {name: "high", label: "High"}

export const defaultShapingLevel: ShapingLevel = LowShapingLevel

export const shapingLevels: ShapingLevel[] = [
    NoneShapingLevel,
    LowShapingLevel,
    MediumShapingLevel,
    HighShapingLevel,
]

export function toShapingLevel(str?: string): ShapingLevel | undefined {
    switch (str) {
        case NoneShapingLevel.name: return NoneShapingLevel
        case LowShapingLevel.name: return LowShapingLevel
        case MediumShapingLevel.name: return MediumShapingLevel
        case HighShapingLevel.name: return HighShapingLevel
    }
}

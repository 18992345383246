// Desc: Main entry point for the application
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import {useState, useEffect} from 'react';
import './App.css';
import LoginPage from './login';
import Dashboard from './dashboard';
import GroupPage from './groups';
import Accounts from './accounts';
import ChangePassword from './change-password';
import { GlobalAuthProvider } from './ui/logoutWarning/logoutWarning';
import env from './config';

function App() {
  const cmsBase = env.REACT_APP_CDN_BASE;
  const cmsDir = env.REACT_APP_CDN_ENV;
  const faviconUrl = cmsBase + "/" + cmsDir + "/favicon.ico";

  useEffect(
    () => {
      const faviconLink = document.querySelector("link[rel='icon']");
      if (faviconLink) {
        faviconLink.setAttribute("href", faviconUrl);
      } else {
        const link = document.createElement('link');
        link.setAttribute("rel", "icon");
        link.setAttribute("href", faviconUrl);
        document.head.appendChild(link);
      }
    }, [])

  return (
    <>
      <Router>
        <GlobalAuthProvider>
          <Routes>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/groups" element={<GroupPage />} />
            <Route path="/accounts" element={<Accounts />} />
            <Route path="/change-password" element={<ChangePassword />} />
            {/* <Route path="/home" element={<Home />} /> Add other routes here */}
          </Routes>
        </GlobalAuthProvider>
      </Router>
    </>
  );
}

export default App;

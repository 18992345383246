// import React from 'react';
// import SideNav from '../ui/dashboard/sidenav';  // Ensure the path is correct

// interface LayoutProps {
//   children: React.ReactNode;
// }

// const Layout: React.FC<LayoutProps> = ({ children }) => {
//   return (
//     <div className="flex h-screen flex-col md:flex-row md:flex-row">
//       <div className="w-full flex-none md:w-64">
//         <SideNav />
//       </div>
//       <div className="flex-grow p-6 md:overflow-y-auto md:p-12">
//         {children}
//       </div>
//     </div>
//   );
// };

// export default Layout;

import React from 'react';
import SideNav from './sidenav';;

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className="layout">
      <SideNav />
      <main className="content">
        {children}
      </main>
    </div>
  );
};

export default Layout;

// import type { Metadata } from "next";
// // import { roboto } from "./ui/fonts";
// import "./globals.css";

// export const metadata: Metadata = {
//   title: "Cora Dashboard",
//   description: "View and manage your accounts and track data useage.",
// };

// export default function RootLayout({
//   children,
// }: Readonly<{
//   children: React.ReactNode;
// }>) {
//   return (
//     <html lang="en">
//       <body className={roboto.className}>{children}</body>
//     </html>
//   );
// }

// import React from 'react';
// // import './globals.css';  // Ensure this path is correct

// interface Metadata {
//   title: string;
//   description: string;
// }

// const metadata: Metadata = {
//   title: "Cora Dashboard",
//   description: "View and manage your accounts and track data usage.",
// };

// interface RootLayoutProps {
//   children: React.ReactNode;
// }

// const Layout: React.FC<RootLayoutProps> = ({ children }) => {
//   return (
//     <html lang="en">
//       <head>
//         <title>{metadata.title}</title>
//         <meta name="description" content={metadata.description} />
//         <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap" />
//       </head>
//       <body className="roboto">
//         {children}
//       </body>
//     </html>
//   );
// };

// export default Layout;


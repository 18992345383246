const countryList = [
    { countryCode: 'AF', countryName: 'Afghanistan', dialCode: '+93', flag: '🇦🇫' },
    { countryCode: 'AL', countryName: 'Albania', dialCode: '+355', flag: '🇦🇱' },
    { countryCode: 'DZ', countryName: 'Algeria', dialCode: '+213', flag: '🇩🇿' },
    { countryCode: 'AS', countryName: 'American Samoa', dialCode: '+1684', flag: '🇦🇸' },
    { countryCode: 'AD', countryName: 'Andorra', dialCode: '+376', flag: '🇦🇩' },
    { countryCode: 'AO', countryName: 'Angola', dialCode: '+244', flag: '🇦🇴' },
    { countryCode: 'AI', countryName: 'Anguilla', dialCode: '+1264', flag: '🇦🇮' },
    { countryCode: 'AQ', countryName: 'Antarctica', dialCode: '+672', flag: '🇦🇶' },
    { countryCode: 'AG', countryName: 'Antigua and Barbuda', dialCode: '+1268', flag: '🇦🇬' },
    { countryCode: 'AR', countryName: 'Argentina', dialCode: '+54', flag: '🇦🇷' },
    { countryCode: 'AM', countryName: 'Armenia', dialCode: '+374', flag: '🇦🇲' },
    { countryCode: 'AW', countryName: 'Aruba', dialCode: '+297', flag: '🇦🇼' },
    { countryCode: 'AU', countryName: 'Australia', dialCode: '+61', flag: '🇦🇺' },
    { countryCode: 'AT', countryName: 'Austria', dialCode: '+43', flag: '🇦🇹' },
    { countryCode: 'AZ', countryName: 'Azerbaijan', dialCode: '+994', flag: '🇦🇿' },
    { countryCode: 'BS', countryName: 'Bahamas', dialCode: '+1242', flag: '🇧🇸' },
    { countryCode: 'BH', countryName: 'Bahrain', dialCode: '+973', flag: '🇧🇭' },
    { countryCode: 'BD', countryName: 'Bangladesh', dialCode: '+880', flag: '🇧🇩' },
    { countryCode: 'BB', countryName: 'Barbados', dialCode: '+1246', flag: '🇧🇧' },
    { countryCode: 'BY', countryName: 'Belarus', dialCode: '+375', flag: '🇧🇾' },
    { countryCode: 'BE', countryName: 'Belgium', dialCode: '+32', flag: '🇧🇪' },
    { countryCode: 'BZ', countryName: 'Belize', dialCode: '+501', flag: '🇧🇿' },
    { countryCode: 'BJ', countryName: 'Benin', dialCode: '+229', flag: '🇧🇯' },
    { countryCode: 'BM', countryName: 'Bermuda', dialCode: '+1441', flag: '🇧🇲' },
    { countryCode: 'BT', countryName: 'Bhutan', dialCode: '+975', flag: '🇧🇹' },
    { countryCode: 'BO', countryName: 'Bolivia', dialCode: '+591', flag: '🇧🇴' },
    { countryCode: 'BA', countryName: 'Bosnia and Herzegovina', dialCode: '+387', flag: '🇧🇦' },
    { countryCode: 'BW', countryName: 'Botswana', dialCode: '+267', flag: '🇧🇼' },
    { countryCode: 'BR', countryName: 'Brazil', dialCode: '+55', flag: '🇧🇷' },
    { countryCode: 'IO', countryName: 'British Indian Ocean Territory', dialCode: '+246', flag: '🇮🇴' },
    { countryCode: 'VG', countryName: 'British Virgin Islands', dialCode: '+1284', flag: '🇻🇬' },
    { countryCode: 'BN', countryName: 'Brunei', dialCode: '+673', flag: '🇧🇳' },
    { countryCode: 'BG', countryName: 'Bulgaria', dialCode: '+359', flag: '🇧🇬' },
    { countryCode: 'BF', countryName: 'Burkina Faso', dialCode: '+226', flag: '🇧🇫' },
    { countryCode: 'BI', countryName: 'Burundi', dialCode: '+257', flag: '🇧🇮' },
    { countryCode: 'KH', countryName: 'Cambodia', dialCode: '+855', flag: '🇰🇭' },
    { countryCode: 'CM', countryName: 'Cameroon', dialCode: '+237', flag: '🇨🇲' },
    { countryCode: 'CA', countryName: 'Canada', dialCode: '+1', flag: '🇨🇦' },
    { countryCode: 'CV', countryName: 'Cape Verde', dialCode: '+238', flag: '🇨🇻' },
    { countryCode: 'KY', countryName: 'Cayman Islands', dialCode: '+1345', flag: '🇰🇾' },
    { countryCode: 'CF', countryName: 'Central African Republic', dialCode: '+236', flag: '🇨🇫' },
    { countryCode: 'TD', countryName: 'Chad', dialCode: '+235', flag: '🇹🇩' },
    { countryCode: 'CL', countryName: 'Chile', dialCode: '+56', flag: '🇨🇱' },
    { countryCode: 'CN', countryName: 'China', dialCode: '+86', flag: '🇨🇳' },
    { countryCode: 'CX', countryName: 'Christmas Island', dialCode: '+61', flag: '🇨🇽' },
    { countryCode: 'CC', countryName: 'Cocos Islands', dialCode: '+61', flag: '🇨🇨' },
    { countryCode: 'CO', countryName: 'Colombia', dialCode: '+57', flag: '🇨🇴' },
    { countryCode: 'KM', countryName: 'Comoros', dialCode: '+269', flag: '🇰🇲' },
    { countryCode: 'CK', countryName: 'Cook Islands', dialCode: '+682', flag: '🇨🇰' },
    { countryCode: 'CR', countryName: 'Costa Rica', dialCode: '+506', flag: '🇨🇷' },
    { countryCode: 'HR', countryName: 'Croatia', dialCode: '+385', flag: '🇭🇷' },
    { countryCode: 'CU', countryName: 'Cuba', dialCode: '+53', flag: '🇨🇺' },
    { countryCode: 'CW', countryName: 'Curaçao', dialCode: '+599', flag: '🇨🇼' },
    { countryCode: 'CY', countryName: 'Cyprus', dialCode: '+357', flag: '🇨🇾' },
    { countryCode: 'CZ', countryName: 'Czech Republic', dialCode: '+420', flag: '🇨🇿' },
    { countryCode: 'CD', countryName: 'Democratic Republic of the Congo', dialCode: '+243', flag: '🇨🇩' },
    { countryCode: 'DK', countryName: 'Denmark', dialCode: '+45', flag: '🇩🇰' },
    { countryCode: 'DJ', countryName: 'Djibouti', dialCode: '+253', flag: '🇩🇯' },
    { countryCode: 'DM', countryName: 'Dominica', dialCode: '+1767', flag: '🇩🇲' },
    { countryCode: 'DO', countryName: 'Dominican Republic', dialCode: '+1', flag: '🇩🇴' },
    { countryCode: 'TL', countryName: 'East Timor', dialCode: '+670', flag: '🇹🇱' },
    { countryCode: 'EC', countryName: 'Ecuador', dialCode: '+593', flag: '🇪🇨' },
    { countryCode: 'EG', countryName: 'Egypt', dialCode: '+20', flag: '🇪🇬' },
    { countryCode: 'SV', countryName: 'El Salvador', dialCode: '+503', flag: '🇸🇻' },
    { countryCode: 'GQ', countryName: 'Equatorial Guinea', dialCode: '+240', flag: '🇬🇶' },
    { countryCode: 'ER', countryName: 'Eritrea', dialCode: '+291', flag: '🇪🇷' },
    { countryCode: 'EE', countryName: 'Estonia', dialCode: '+372', flag: '🇪🇪' },
    { countryCode: 'ET', countryName: 'Ethiopia', dialCode: '+251', flag: '🇪🇹' },
    { countryCode: 'FK', countryName: 'Falkland Islands', dialCode: '+500', flag: '🇫🇰' },
    { countryCode: 'FO', countryName: 'Faroe Islands', dialCode: '+298', flag: '🇫🇴' },
    { countryCode: 'FJ', countryName: 'Fiji', dialCode: '+679', flag: '🇫🇯' },
    { countryCode: 'FI', countryName: 'Finland', dialCode: '+358', flag: '🇫🇮' },
    { countryCode: 'FR', countryName: 'France', dialCode: '+33', flag: '🇫🇷' },
    { countryCode: 'PF', countryName: 'French Polynesia', dialCode: '+689', flag: '🇵🇫' },
    { countryCode: 'GA', countryName: 'Gabon', dialCode: '+241', flag: '🇬🇦' },
    { countryCode: 'GM', countryName: 'Gambia', dialCode: '+220', flag: '🇬🇲' },
    { countryCode: 'GE', countryName: 'Georgia', dialCode: '+995', flag: '🇬🇪' },
    { countryCode: 'DE', countryName: 'Germany', dialCode: '+49', flag: '🇩🇪' },
    { countryCode: 'GH', countryName: 'Ghana', dialCode: '+233', flag: '🇬🇭' },
    { countryCode: 'GI', countryName: 'Gibraltar', dialCode: '+350', flag: '🇬🇮' },
    { countryCode: 'GR', countryName: 'Greece', dialCode: '+30', flag: '🇬🇷' },
    { countryCode: 'GL', countryName: 'Greenland', dialCode: '+299', flag: '🇬🇱' },
    { countryCode: 'GD', countryName: 'Grenada', dialCode: '+1473', flag: '🇬🇩' },
    { countryCode: 'GU', countryName: 'Guam', dialCode: '+1671', flag: '🇬🇺' },
    { countryCode: 'GT', countryName: 'Guatemala', dialCode: '+502', flag: '🇬🇹' },
    { countryCode: 'GG', countryName: 'Guernsey', dialCode: '+44', flag: '🇬🇬' },
    { countryCode: 'GN', countryName: 'Guinea', dialCode: '+224', flag: '🇬🇳' },
    { countryCode: 'GW', countryName: 'Guinea-Bissau', dialCode: '+245', flag: '🇬🇼' },
    { countryCode: 'GY', countryName: 'Guyana', dialCode: '+592', flag: '🇬🇾' },
    { countryCode: 'HT', countryName: 'Haiti', dialCode: '+509', flag: '🇭🇹' },
    { countryCode: 'HN', countryName: 'Honduras', dialCode: '+504', flag: '🇭🇳' },
    { countryCode: 'HK', countryName: 'Hong Kong', dialCode: '+852', flag: '🇭🇰' },
    { countryCode: 'HU', countryName: 'Hungary', dialCode: '+36', flag: '🇭🇺' },
    { countryCode: 'IS', countryName: 'Iceland', dialCode: '+354', flag: '🇮🇸' },
    { countryCode: 'IN', countryName: 'India', dialCode: '+91', flag: '🇮🇳' },
    { countryCode: 'ID', countryName: 'Indonesia', dialCode: '+62', flag: '🇮🇩' },
    { countryCode: 'IR', countryName: 'Iran', dialCode: '+98', flag: '🇮🇷' },
    { countryCode: 'IQ', countryName: 'Iraq', dialCode: '+964', flag: '🇮🇶' },
    { countryCode: 'IE', countryName: 'Ireland', dialCode: '+353', flag: '🇮🇪' },
    { countryCode: 'IM', countryName: 'Isle of Man', dialCode: '+44', flag: '🇮🇲' },
    { countryCode: 'IL', countryName: 'Israel', dialCode: '+972', flag: '🇮🇱' },
    { countryCode: 'IT', countryName: 'Italy', dialCode: '+39', flag: '🇮🇹' },
    { countryCode: 'CI', countryName: 'Ivory Coast', dialCode: '+225', flag: '🇨🇮' },
    { countryCode: 'JM', countryName: 'Jamaica', dialCode: '+1876', flag: '🇯🇲' },
    { countryCode: 'JP', countryName: 'Japan', dialCode: '+81', flag: '🇯🇵' },
    { countryCode: 'JE', countryName: 'Jersey', dialCode: '+44', flag: '🇯🇪' },
    { countryCode: 'JO', countryName: 'Jordan', dialCode: '+962', flag: '🇯🇴' },
    { countryCode: 'KZ', countryName: 'Kazakhstan', dialCode: '+7', flag: '🇰🇿' },
    { countryCode: 'KE', countryName: 'Kenya', dialCode: '+254', flag: '🇰🇪' },
    { countryCode: 'KI', countryName: 'Kiribati', dialCode: '+686', flag: '🇰🇮' },
    { countryCode: 'XK', countryName: 'Kosovo', dialCode: '+383', flag: '🇽🇰' },
    { countryCode: 'KW', countryName: 'Kuwait', dialCode: '+965', flag: '🇰🇼' },
    { countryCode: 'KG', countryName: 'Kyrgyzstan', dialCode: '+996', flag: '🇰🇬' },
    { countryCode: 'LA', countryName: 'Laos', dialCode: '+856', flag: '🇱🇦' },
    { countryCode: 'LV', countryName: 'Latvia', dialCode: '+371', flag: '🇱🇻' },
    { countryCode: 'LB', countryName: 'Lebanon', dialCode: '+961', flag: '🇱🇧' },
    { countryCode: 'LS', countryName: 'Lesotho', dialCode: '+266', flag: '🇱🇸' },
    { countryCode: 'LR', countryName: 'Liberia', dialCode: '+231', flag: '🇱🇷' },
    { countryCode: 'LY', countryName: 'Libya', dialCode: '+218', flag: '🇱🇾' },
    { countryCode: 'LI', countryName: 'Liechtenstein', dialCode: '+423', flag: '🇱🇮' },
    { countryCode: 'LT', countryName: 'Lithuania', dialCode: '+370', flag: '🇱🇹' },
    { countryCode: 'LU', countryName: 'Luxembourg', dialCode: '+352', flag: '🇱🇺' },
    { countryCode: 'MO', countryName: 'Macau', dialCode: '+853', flag: '🇲🇴' },
    { countryCode: 'MK', countryName: 'North Macedonia', dialCode: '+389', flag: '🇲🇰' },
    { countryCode: 'MG', countryName: 'Madagascar', dialCode: '+261', flag: '🇲🇬' },
    { countryCode: 'MW', countryName: 'Malawi', dialCode: '+265', flag: '🇲🇼' },
    { countryCode: 'MY', countryName: 'Malaysia', dialCode: '+60', flag: '🇲🇾' },
    { countryCode: 'MV', countryName: 'Maldives', dialCode: '+960', flag: '🇲🇻' },
    { countryCode: 'ML', countryName: 'Mali', dialCode: '+223', flag: '🇲🇱' },
    { countryCode: 'MT', countryName: 'Malta', dialCode: '+356', flag: '🇲🇹' },
    { countryCode: 'MH', countryName: 'Marshall Islands', dialCode: '+692', flag: '🇲🇭' },
    { countryCode: 'MR', countryName: 'Mauritania', dialCode: '+222', flag: '🇲🇷' },
    { countryCode: 'MU', countryName: 'Mauritius', dialCode: '+230', flag: '🇲🇺' },
    { countryCode: 'YT', countryName: 'Mayotte', dialCode: '+262', flag: '🇾🇹' },
    { countryCode: 'MX', countryName: 'Mexico', dialCode: '+52', flag: '🇲🇽' },
    { countryCode: 'FM', countryName: 'Micronesia', dialCode: '+691', flag: '🇫🇲' },
    { countryCode: 'MD', countryName: 'Moldova', dialCode: '+373', flag: '🇲🇩' },
    { countryCode: 'MC', countryName: 'Monaco', dialCode: '+377', flag: '🇲🇨' },
    { countryCode: 'MN', countryName: 'Mongolia', dialCode: '+976', flag: '🇲🇳' },
    { countryCode: 'ME', countryName: 'Montenegro', dialCode: '+382', flag: '🇲🇪' },
    { countryCode: 'MS', countryName: 'Montserrat', dialCode: '+1664', flag: '🇲🇸' },
    { countryCode: 'MA', countryName: 'Morocco', dialCode: '+212', flag: '🇲🇦' },
    { countryCode: 'MZ', countryName: 'Mozambique', dialCode: '+258', flag: '🇲🇿' },
    { countryCode: 'MM', countryName: 'Myanmar', dialCode: '+95', flag: '🇲🇲' },
    { countryCode: 'NA', countryName: 'Namibia', dialCode: '+264', flag: '🇳🇦' },
    { countryCode: 'NR', countryName: 'Nauru', dialCode: '+674', flag: '🇳🇷' },
    { countryCode: 'NP', countryName: 'Nepal', dialCode: '+977', flag: '🇳🇵' },
    { countryCode: 'NL', countryName: 'Netherlands', dialCode: '+31', flag: '🇳🇱' },
    { countryCode: 'NC', countryName: 'New Caledonia', dialCode: '+687', flag: '🇳🇨' },
    { countryCode: 'NZ', countryName: 'New Zealand', dialCode: '+64', flag: '🇳🇿' },
    { countryCode: 'NI', countryName: 'Nicaragua', dialCode: '+505', flag: '🇳🇮' },
    { countryCode: 'NE', countryName: 'Niger', dialCode: '+227', flag: '🇳🇪' },
    { countryCode: 'NG', countryName: 'Nigeria', dialCode: '+234', flag: '🇳🇬' },
    { countryCode: 'NU', countryName: 'Niue', dialCode: '+683', flag: '🇳🇺' },
    { countryCode: 'KP', countryName: 'North Korea', dialCode: '+850', flag: '🇰🇵' },
    { countryCode: 'NO', countryName: 'Norway', dialCode: '+47', flag: '🇳🇴' },
    { countryCode: 'OM', countryName: 'Oman', dialCode: '+968', flag: '🇴🇲' },
    { countryCode: 'PK', countryName: 'Pakistan', dialCode: '+92', flag: '🇵🇰' },
    { countryCode: 'PW', countryName: 'Palau', dialCode: '+680', flag: '🇵🇼' },
    { countryCode: 'PS', countryName: 'Palestine', dialCode: '+970', flag: '🇵🇸' },
    { countryCode: 'PA', countryName: 'Panama', dialCode: '+507', flag: '🇵🇦' },
    { countryCode: 'PG', countryName: 'Papua New Guinea', dialCode: '+675', flag: '🇵🇬' },
    { countryCode: 'PY', countryName: 'Paraguay', dialCode: '+595', flag: '🇵🇾' },
    { countryCode: 'PE', countryName: 'Peru', dialCode: '+51', flag: '🇵🇪' },
    { countryCode: 'PH', countryName: 'Philippines', dialCode: '+63', flag: '🇵🇭' },
    { countryCode: 'PL', countryName: 'Poland', dialCode: '+48', flag: '🇵🇱' },
    { countryCode: 'PT', countryName: 'Portugal', dialCode: '+351', flag: '🇵🇹' },
    { countryCode: 'PR', countryName: 'Puerto Rico', dialCode: '+1', flag: '🇵🇷' },
    { countryCode: 'QA', countryName: 'Qatar', dialCode: '+974', flag: '🇶🇦' },
    { countryCode: 'CG', countryName: 'Republic of the Congo', dialCode: '+242', flag: '🇨🇬' },
    { countryCode: 'RE', countryName: 'Réunion', dialCode: '+262', flag: '🇷🇪' },
    { countryCode: 'RO', countryName: 'Romania', dialCode: '+40', flag: '🇷🇴' },
    { countryCode: 'RU', countryName: 'Russia', dialCode: '+7', flag: '🇷🇺' },
    { countryCode: 'RW', countryName: 'Rwanda', dialCode: '+250', flag: '🇷🇼' },
    { countryCode: 'BL', countryName: 'Saint Barthélemy', dialCode: '+590', flag: '🇧🇱' },
    { countryCode: 'SH', countryName: 'Saint Helena', dialCode: '+290', flag: '🇸🇭' },
    { countryCode: 'KN', countryName: 'Saint Kitts and Nevis', dialCode: '+1869', flag: '🇰🇳' },
    { countryCode: 'LC', countryName: 'Saint Lucia', dialCode: '+1758', flag: '🇱🇨' },
    { countryCode: 'MF', countryName: 'Saint Martin', dialCode: '+590', flag: '🇲🇫' },
    { countryCode: 'PM', countryName: 'Saint Pierre and Miquelon', dialCode: '+508', flag: '🇵🇲' },
    { countryCode: 'VC', countryName: 'Saint Vincent and the Grenadines', dialCode: '+1784', flag: '🇻🇨' },
    { countryCode: 'WS', countryName: 'Samoa', dialCode: '+685', flag: '🇼🇸' },
    { countryCode: 'SM', countryName: 'San Marino', dialCode: '+378', flag: '🇸🇲' },
    { countryCode: 'ST', countryName: 'São Tomé and Príncipe', dialCode: '+239', flag: '🇸🇹' },
    { countryCode: 'SA', countryName: 'Saudi Arabia', dialCode: '+966', flag: '🇸🇦' },
    { countryCode: 'SN', countryName: 'Senegal', dialCode: '+221', flag: '🇸🇳' },
    { countryCode: 'RS', countryName: 'Serbia', dialCode: '+381', flag: '🇷🇸' },
    { countryCode: 'SC', countryName: 'Seychelles', dialCode: '+248', flag: '🇸🇨' },
    { countryCode: 'SL', countryName: 'Sierra Leone', dialCode: '+232', flag: '🇸🇱' },
    { countryCode: 'SG', countryName: 'Singapore', dialCode: '+65', flag: '🇸🇬' },
    { countryCode: 'SX', countryName: 'Sint Maarten', dialCode: '+1721', flag: '🇸🇽' },
    { countryCode: 'SK', countryName: 'Slovakia', dialCode: '+421', flag: '🇸🇰' },
    { countryCode: 'SI', countryName: 'Slovenia', dialCode: '+386', flag: '🇸🇮' },
    { countryCode: 'SB', countryName: 'Solomon Islands', dialCode: '+677', flag: '🇸🇧' },
    { countryCode: 'SO', countryName: 'Somalia', dialCode: '+252', flag: '🇸🇴' },
    { countryCode: 'ZA', countryName: 'South Africa', dialCode: '+27', flag: '🇿🇦' },
    { countryCode: 'KR', countryName: 'South Korea', dialCode: '+82', flag: '🇰🇷' },
    { countryCode: 'SS', countryName: 'South Sudan', dialCode: '+211', flag: '🇸🇸' },
    { countryCode: 'ES', countryName: 'Spain', dialCode: '+34', flag: '🇪🇸' },
    { countryCode: 'LK', countryName: 'Sri Lanka', dialCode: '+94', flag: '🇱🇰' },
    { countryCode: 'SD', countryName: 'Sudan', dialCode: '+249', flag: '🇸🇩' },
    { countryCode: 'SR', countryName: 'Suriname', dialCode: '+597', flag: '🇸🇷' },
    { countryCode: 'SZ', countryName: 'Eswatini', dialCode: '+268', flag: '🇸🇿' },
    { countryCode: 'SE', countryName: 'Sweden', dialCode: '+46', flag: '🇸🇪' },
    { countryCode: 'CH', countryName: 'Switzerland', dialCode: '+41', flag: '🇨🇭' },
    { countryCode: 'SY', countryName: 'Syria', dialCode: '+963', flag: '🇸🇾' },
    { countryCode: 'TW', countryName: 'Taiwan', dialCode: '+886', flag: '🇹🇼' },
    { countryCode: 'TJ', countryName: 'Tajikistan', dialCode: '+992', flag: '🇹🇯' },
    { countryCode: 'TZ', countryName: 'Tanzania', dialCode: '+255', flag: '🇹🇿' },
    { countryCode: 'TH', countryName: 'Thailand', dialCode: '+66', flag: '🇹🇭' },
    { countryCode: 'TG', countryName: 'Togo', dialCode: '+228', flag: '🇹🇬' },
    { countryCode: 'TK', countryName: 'Tokelau', dialCode: '+690', flag: '🇹🇰' },
    { countryCode: 'TO', countryName: 'Tonga', dialCode: '+676', flag: '🇹🇴' },
    { countryCode: 'TT', countryName: 'Trinidad and Tobago', dialCode: '+1868', flag: '🇹🇹' },
    { countryCode: 'TN', countryName: 'Tunisia', dialCode: '+216', flag: '🇹🇳' },
    { countryCode: 'TR', countryName: 'Turkey', dialCode: '+90', flag: '🇹🇷' },
    { countryCode: 'TM', countryName: 'Turkmenistan', dialCode: '+993', flag: '🇹🇲' },
    { countryCode: 'TC', countryName: 'Turks and Caicos Islands', dialCode: '+1649', flag: '🇹🇨' },
    { countryCode: 'TV', countryName: 'Tuvalu', dialCode: '+688', flag: '🇹🇻' },
    { countryCode: 'UG', countryName: 'Uganda', dialCode: '+256', flag: '🇺🇬' },
    { countryCode: 'UA', countryName: 'Ukraine', dialCode: '+380', flag: '🇺🇦' },
    { countryCode: 'AE', countryName: 'United Arab Emirates', dialCode: '+971', flag: '🇦🇪' },
    { countryCode: 'GB', countryName: 'United Kingdom', dialCode: '+44', flag: '🇬🇧' },
    { countryCode: 'US', countryName: 'United States', dialCode: '+1', flag: '🇺🇸' },
    { countryCode: 'UY', countryName: 'Uruguay', dialCode: '+598', flag: '🇺🇾' },
    { countryCode: 'VI', countryName: 'U.S. Virgin Islands', dialCode: '+1340', flag: '🇻🇮' },
    { countryCode: 'UZ', countryName: 'Uzbekistan', dialCode: '+998', flag: '🇺🇿' },
    { countryCode: 'VU', countryName: 'Vanuatu', dialCode: '+678', flag: '🇻🇺' },
    { countryCode: 'VA', countryName: 'Vatican City', dialCode: '+379', flag: '🇻🇦' },
    { countryCode: 'VE', countryName: 'Venezuela', dialCode: '+58', flag: '🇻🇪' },
    { countryCode: 'VN', countryName: 'Vietnam', dialCode: '+84', flag: '🇻🇳' },
    { countryCode: 'WF', countryName: 'Wallis and Futuna', dialCode: '+681', flag: '🇼🇫' },
    { countryCode: 'EH', countryName: 'Western Sahara', dialCode: '+212', flag: '🇪🇭' },
    { countryCode: 'YE', countryName: 'Yemen', dialCode: '+967', flag: '🇾🇪' },
    { countryCode: 'ZM', countryName: 'Zambia', dialCode: '+260', flag: '🇿🇲' },
    { countryCode: 'ZW', countryName: 'Zimbabwe', dialCode: '+263', flag: '🇿🇼' },
  ];

export default countryList;
"use client";

import React, { useState, useEffect } from 'react';
import { getGroupData } from '../../api/shared';
import Loader from '../loader/loader';
import './other.css';

interface DataUsageComponentProps {
    groupData: any;
    selectedGroup: string;
    startDate: Date | null;
    endDate: Date | null;
    loadingClear: boolean;
}

const formatDataUsage = (bytes: number): string => {
    const MiB = 1 << 20; // 2^20 bytes
    const GiB = 1 << 30; // 2^30 bytes

    // if (bytes >= GiB) {
    //     return (bytes / GiB).toFixed(1) + ' GB';
    // } else {
    //     return (bytes / MiB).toFixed(1) + ' MB';
    // }
    return (bytes / MiB).toFixed(1) + ' MB';
};

const DataUsageComponent: React.FC<DataUsageComponentProps> = ({ groupData, selectedGroup, startDate, endDate, loadingClear }) => {
    const [data, setData] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [totalDataUsage, setTotalDataUsage] = useState<number>(0);

    useEffect(() => {
        const fetchData = async () => {
            if (selectedGroup) {
                setLoading(true);
                try {
                    const response = groupData;

                    const filteredData = response.filter((item: any) => {
                        const itemStartTime = item.startTime ? new Date(item.startTime) : null;
                        const itemStopTime = item.stopTime ? new Date(item.stopTime) : null;

                        return (
                            (!startDate || (itemStartTime && itemStartTime >= startDate)) &&
                            (!endDate || (itemStopTime && itemStopTime <= endDate))
                        );
                    });
                    setData(filteredData);
                    setLoading(false);

                    if (filteredData && filteredData.length > 0) {
                        const totalUsage = filteredData.reduce((acc: number, curr: any) => {
                            return acc + (curr.dataUsage || 0);
                        }, 0);
                        setTotalDataUsage(totalUsage);
                    } else {
                        setTotalDataUsage(0);
                    }
                } catch (error) {
                    console.error("Error fetching data:", error);
                    setLoading(false);
                }
            }
        };

        fetchData();
    }, [groupData, selectedGroup, startDate, endDate]);

    if (loading) {
        return <p className='device-count'>N/A</p>;
    }

    if (loadingClear) {
        return <p className='device-count'>Loading...</p>;
    }

    return (
        <div>
           <p className="device-count">{formatDataUsage(totalDataUsage)}</p>
        </div>
    );
}

export default DataUsageComponent;

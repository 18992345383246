"use client";

import { useNavigate } from 'react-router-dom';
import React, { useState } from "react";
import { Pane, Heading, majorScale, toaster, EyeOpenIcon, EyeOffIcon} from 'evergreen-ui';
import { changePassword } from '../api/shared';
import './changePassword.css';

const PasswordInput = ({ label, value, onChange, showPassword, setShowPassword}: { label: string, value: string, onChange: (e: React.ChangeEvent<HTMLInputElement>) => void, showPassword: boolean, setShowPassword: (show: boolean) => void }) => {
    return (
        <div className="input-group">
            <label className="label">
                {label}<span className="red-asterisk">*</span>
            </label>
            <div className="passwordWrapper">
                <input
                    className="input"
                    type={showPassword ? 'text' : 'password'}
                    value={value}
                    onChange={onChange}
                    required
                />
                <button
                    type="button"
                    className="showPasswordButton"
                    onClick={() => setShowPassword(!showPassword)}
                >
                    {showPassword ? <EyeOffIcon /> : <EyeOpenIcon />}
                </button>
            </div>
        </div>
    );
};

const ChangePassword: React.FC = () => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);

    const [newPassword, setNewPassword] = useState('');
    const [showNewPassword, setShowNewPassword] = useState(false);

    const [confirmPassword, setConfirmPassword] = useState('');
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const navigate = useNavigate();
  
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!currentPassword || !newPassword || !confirmPassword) {
            toaster.danger('All fields are required.');
            return;
        }

        if (newPassword !== confirmPassword) {
            toaster.danger('Passwords do not match.');
            return;
        }
  
        if (newPassword.length < 8) {
            toaster.danger('Password must be at least 8 characters long.');
            return;
        }
  
        // Example success handling, replace with actual password update logic
        try {
            await changePassword(currentPassword, newPassword);
            toaster.success('Password successfully changed!');
            // Redirect to login page after a short delay
            setTimeout(() => {
                navigate('/login');
            }, 2000); // Adjust delay as needed
        } catch (error) {
            toaster.danger('Failed to change password. Please try again.');
            return;
        }
    };
  
    return (
        <Pane className="change-password-background">
            <Pane
                className="custom-form"
                elevation={1}
                width={400}
                padding={majorScale(3)}
                borderRadius={8}
            >
                <Heading size={600} marginBottom={majorScale(2)}>
                    Change Password
                </Heading>
                <form onSubmit={handleSubmit}>
                    <PasswordInput
                        label="Current Password"
                        value={currentPassword}
                        onChange={(e: any) => setCurrentPassword(e.target.value)}
                        showPassword={showCurrentPassword}
                        setShowPassword={setShowCurrentPassword}
                    />
                    <PasswordInput
                        label="New Password"
                        value={newPassword}
                        onChange={(e: any) => setNewPassword(e.target.value)}
                        showPassword={showNewPassword}
                        setShowPassword={setShowNewPassword}
                    />
                    <PasswordInput
                        label="Confirm Password"
                        value={confirmPassword}
                        onChange={(e: any) => setConfirmPassword(e.target.value)}
                        showPassword={showConfirmPassword}
                        setShowPassword={setShowConfirmPassword}
                    />
                    <button className="custom-button" type="submit">Change Password</button>
                </form>
            </Pane>
        </Pane>
    );
};
  
export default ChangePassword;
"use client";

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IconLogout } from "@tabler/icons-react";
import { HelpIcon, Popover, Pane, Button, Tooltip, Paragraph, Dialog } from "evergreen-ui";
import NavLinks from "./nav-links";
import LogoSvgFunc from "./logoSvg";
import "./sideStyles.css";

export default function SideNav() {
  const [isOpen, setIsOpen] = useState(false);
  const [contactDialogOpen, setContactDialogOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogout = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault(); // Prevent form submission
    navigate("/login");
    localStorage.removeItem("refreshData");
    sessionStorage.removeItem("authData");
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  // Function to handle opening links in a new tab
  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const imageTag = process.env.REACT_APP_IMAGE_TAG || 'unknown';

  return (
    <>
      {/* Hamburger Menu */}
      <div
        className={`hamburger-menu ${isOpen ? "open" : ""}`}
        onClick={toggleSidebar}
      >
        <div className="hamburger-icon">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      {/* Sidebar */}
      <div
        className={`sidebar ${
          isOpen ? "open" : ""
        } flex flex-col px-3 py-4 md:px-2`}
      >
        {/* Logo */}
        <Link
          className="mb-2"
          style={{
            backgroundColor: "transparent",
            paddingLeft: "1rem",
            paddingTop: "1rem",
          }}
          to="/dashboard"
        >
          <div
            className="w-32 md:w-40"
            style={{ color: "rgb(var(--header-text-color))" }}
          >
            <LogoSvgFunc />
          </div>
        </Link>

        {/* Navigation Links */}
        <NavLinks />

        {/* Spacer to push buttons to the bottom */}
        <div className="flex-grow"></div>

        {/* Logout and Help Buttons */}
        <div className="flex items-center w-full mt-auto">
            {/* Logout Button */}
            <button
              className="flex h-[48px] items-center justify-center gap-2 rounded-md bg-grey-50 p-3 text-sm font-medium hover:bg-sky-100 hover:text-blue-600 md:flex-none md:justify-start md:p-2 md:px-3"
              onClick={handleLogout}
            >
              <IconLogout className="w-6" />
              <span className="hidden md:block">Logout</span>
            </button>   
            {/* Help Icon with Popover */}
            <div className="ml-auto">
                <Popover
                  position="bottom"
                  content={
                    <Pane padding={16} width={480}>
                      <Pane
                        display="grid"
                        gridTemplateColumns="1fr 1fr"
                        gap={16} /* Gap between grid items */
                      >
                        {/* First item */}
                        <Pane display="flex" flexDirection="column" alignItems="center">
                          <Paragraph textAlign="center">
                            Manage your support tickets and view updates for your company's issues.
                          </Paragraph>
                          <Button
                            appearance="default"
                            intent="none"
                            onClick={() => openInNewTab("https://portal.skypeaktechnologies.com/tickets-view?offset=0")}
                          >
                            Customer Portal
                          </Button>
                        </Pane>
                        {/* Second item */}
                        <Pane display="flex" flexDirection="column" alignItems="center">
                          <Paragraph textAlign="center">
                            Browse our knowledge base for quick answers, tips, and troubleshooting solutions.
                          </Paragraph>
                          <Button
                            appearance="default"
                            intent="none"
                            onClick={() => openInNewTab("https://portal.skypeaktechnologies.com")}
                          >
                            FAQs
                          </Button>
                        </Pane>
                        {/* Third item */}
                        <Pane display="flex" flexDirection="column" alignItems="center">
                          <Paragraph textAlign="center">
                            Reach out to our support team directly via email for personalized assistance.
                          </Paragraph>
                          <Button
                            appearance="default"
                            intent="none"
                            onClick={() => setContactDialogOpen(true)}
                          >
                            Contact Us
                          </Button>
                        </Pane>
                        {/* Fourth item */}
                        <Pane display="flex" flexDirection="column" alignItems="center">
                          <Paragraph textAlign="center">
                            Inform us of any issues or suggest improvements to enhance your experience.
                          </Paragraph>
                          <Button
                            appearance="default"
                            intent="none"
                            onClick={() => openInNewTab("https://share.hsforms.com/1QlxUBdPeSeSlk848RkSY6Q44adj")}
                          >
                            Report a Bug
                          </Button>
                        </Pane>
                      </Pane>
                      {/* Image Tag at the Bottom Right */}
                      <Pane
                        position="absolute"
                        bottom={8}
                        right={16}
                        style={{ opacity: 0.6 }}
                      >
                        <Paragraph size={300} color="muted" textAlign="right">
                          v{imageTag}
                        </Paragraph>
                      </Pane>
                    </Pane>
                  }
                >
                  {/* Trigger button for Popover */}
                  <Tooltip content="Help Menu">
                    <button
                      type="button"
                      aria-label="Help"
                      className="flex h-[48px] items-center justify-center rounded-md bg-grey-50 p-3 text-sm font-medium hover:bg-sky-100 hover:text-blue-600 md:flex-none md:justify-start md:p-2 md:px-3 ml-auto"
                    >
                      <HelpIcon size={24} />
                    </button>
                  </Tooltip>
                </Popover>
            </div>
        </div>
      </div>
      <Dialog
        isShown={contactDialogOpen}
        title="Contact Us"
        onCloseComplete={() => setContactDialogOpen(false)}
        confirmLabel="Close"
      >
        <Paragraph>
          If you can't find the answers you're looking for in our FAQs, please feel free to reach out
          to us at{" "}
          <a href="mailto:support@skypeaktech.com" style={{ color: "#1070CA" }}>
            support@skypeaktech.com
          </a>
          .
        </Paragraph>
      </Dialog>
    </>
  );
}
